import React, { useCallback, useEffect, useState,forwardRef, useRef, useImperativeHandle } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { TextInput } from '../form-elements/textInput'
import { SelectInput } from '../form-elements/dropdown'
import { MaskInput } from '../form-elements/maskInput';
import { useFacilityApi } from '../../hooks/api/facilityApiHook';
import { useCommonApi } from '../../hooks/api/commonApiHook';
import { useStaffApi } from '../../hooks/api/staffApiHook';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ReadCheckHOC from '../../hoc/readCheckHoc';
import { MODULES } from '../../config/config';
import debounce from 'lodash.debounce';
import CreatableSelect from 'react-select/creatable';
import { useUserSelector } from '../../hooks/selectors/userSelector';


const schema = yup.object().shape({
   // name: yup.string().required('Name is required').matches(/^[a-zA-Z0-9 ]+$/, 'Invalid name').max(90, 'Max 90 characters'),
   name: yup.string().required('Name is required').matches(/^[ A-Za-z0-9_@./#&+-]*$/, 'Invalid name').max(90, 'Max 90 characters'),
   addrLine1: yup.string().required('Address line 1 is required').max(150, 'Max 150 characters'),
   addrLine2: yup.string().max(150, 'Max 150 characters'),
   streetAddr: yup.string().max(250, 'Max 250 characters'),
   phone: yup.string().required('Phone is required').transform(val => val.replace(/[^\d]/g, '')).test('len', 'Invalid phone number', val => val.length === 10),
   // adminPhone: yup.string().notRequired().transform(val => val.replace(/[^\d]/g, '')).test('len', 'Invalid phone number', val => !val.length || (val && val.length === 10)),
   zipCode: yup.string().required('Zip is required').matches(/^[a-zA-Z0-9\\-]+$/i, 'Zip can only contain letters, numbers and "-"').min(5, 'Minimum 5 characters'),
   // administrator: yup.string().required('Administrator is required').max(200, 'Max 200 characters'),
   liscenseNo: yup.string().max(200, 'Max 200 characters'),
   staff: yup.object().shape({
      label: yup.string(),
      value: yup.string()
   }).required('Staff is required'),
   state: yup.object().shape({
      label: yup.string(),
      value: yup.string()
   }).nullable().required('State is required')
})

const FacilityForm =  forwardRef((props, ref) =>{
   const refe = React.useRef()

   const [stateList, setStates] = useState([])
   const [cityList, setCities] = useState([])
   const [selectedCity, setCity] = useState('')
   const [search, setSearch] = useState('')
   const [loading, setLoading] = useState(false)
   const [isOpen, setOpen] = useState(false)
   const [isWizard, setWizard] = useState(props.isWizard)
   const [users, setUsers] = useState([])
   // const [selectedUser, setUser] = useState('')

   const { handleSubmit, errors, control, reset, getValues, watch } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema)
   });

   const facilityApi = useFacilityApi()
   const history = useHistory()
   const commonApi = useCommonApi()
   const staffApi = useStaffApi()
   const user = useUserSelector()


   const selectedState = watch('state')

   useEffect(() => {
      getList(1)
      
   },[])

   useEffect(() => {
      if (props.facId) {
         setData()
      }
      getStatesList()
   }, [props.activeTab])


  
   const skiptonextstep = ()=>{
      //alert('here i am');
      props.onChangeStep(props.activeTab)
      //props.activeTab;
   }


   const setData = () => {
      facilityApi.getFacility({ id: props.facId },
         (message, resp) => {
            let temp = { ...resp }
            temp.state = resp.state ? { label: resp.state.state_code, value: resp.state.id } : ''
            temp.staff = resp.adminUser ? { label: resp.adminUser.firstName + ' ' +resp.adminUser.lastName , value: resp.adminUser.id } : '';
            // setUser(adminFacility)
            setCity(resp.city && resp.state ? { label: resp.city, value: "adbc54" } : '')
            reset(temp)
         }, (message, resp) => {
            if (resp.status === 404) {
                 toast.error('No such facility found')
               setTimeout(() => {
                  history.replace('/facility-list')
               }, 500);
            }
            else {
               toast.error(message)
            }
         })
   }

   const getStatesList = useCallback(() => {
      commonApi.getStates({ search: '' },
         (message, resp) => {
            setStates(resp.length ? resp.map(item => { return { label: item.state_code, value: item.id } }) : [])
         }, (message) => {
            //toast.error(message)
         })
   }, [])

   const getList = (page = 1, additional = {}) => {
      let params = { page, limit: 99999, userId: user.id, ...additional }

      // staffApi.staffLists(params,
      staffApi.administratorList(params,
         (message, resp) => {
            console.log(resp)
            if (resp.data.length) {
               // let temp = resp.data.length ? resp.data.map(item => { return { label: item.firstName + ' ' + item.lastName, value: item.id } }) : []
               setUsers(resp.data.length ? resp.data.map(item => { return { label: item.firstName + ' ' + item.lastName, value: item.id } }) : [])
            }
         }, (message) => { })
   }

   const onSubmit = (data) => {
      if (!selectedCity || !selectedCity.label) {
         toast.error('Please choose a city')
         return
      }
      if (selectedCity && selectedCity.label.length > 100) {
         toast.error('Max length for city is 100 characters')
         return
      }

      data.stateId = parseInt(data.state.value)
      data.city = selectedCity.label.trim()
      data.adminUserId = data.staff.value;
      delete data.staff
      
      if (props.facId) {
         data['id'] = props.facId
         facilityApi.updateFacility(data,
            (message, resp) => {
               toast.success('Facility updated successfully')
               history.push('/facility-list')
            }, (message) => {
               //toast.error(message)
            })
      }
      else {
         facilityApi.addFacility(data,
            (message, resp) => {
               toast.success('Facility created successfully')
               if(isWizard === true){
                  skiptonextstep()
               }
               else{
                  history.push('/facility-list')
               }
               
               //
               
            }, (message) => {
               //toast.error(message)
            })
            
      }
   }




   const resetCity = () => {
      if (selectedState && selectedState.value) {
         setCities([])
         setCity('')
      }
   }

   const handleSearch = (inputValue = '') => {
      setLoading(true)
      commonApi.getCities({ page: 1, limit: 10, search: inputValue, state: getValues().state.value },
         (message, resp) => {
            let temp = resp.data.map(item => { return { label: `${item.city}`, value: item.id } })
            setCities(temp)
            setSearch(inputValue)
            setLoading(false)
            setOpen(true)
         }, (message) => {
            //toast.error(message)
         })
   }

   const searchFunc = useCallback(debounce(val => {
      handleSearch(val);
   }, 1500), []);

   const typeAndSearch = (val) => {
      setSearch(val)
      if (val.length >= 3) {
         searchFunc(val)
      }
   }

   const handleSelect = (val) => {
      setCity(val)
   }

   useImperativeHandle(ref, () => ({
      getAlert() {
        return getValues()
      }
    }));

   return (
      <>
      {console.log("propspropspropspropspropspropsprops",props)}
      <form onSubmit={handleSubmit(onSubmit)}>
         <fieldset disabled={props.isView}>
            <div className="row f_1">
               <div className="col-lg-8">
                  <div className="card">
                     <div className="card-body">
                        <div className="row">
                           <div className="col-lg-12" data-select2-id={1}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="name"
                                    render={({ ref, value, ...props }) => (
                                       <TextInput
                                          inputref={ref}
                                          value={value}
                                          error={errors.name}
                                          label='Name'
                                          maxLength={90}
                                          required
                                          type='text'
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-lg-12" data-select2-id={1}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="addrLine1"
                                    render={({ ref, value, ...props }) => (
                                       <TextInput
                                          inputref={ref}
                                          value={value}
                                          error={errors.addrLine1}
                                          label='Address Line 1'
                                          maxLength={150}
                                          required
                                          type='textarea'
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-lg-12" data-select2-id={1}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="addrLine2"
                                    render={({ ref, value, ...props }) => (
                                       <TextInput
                                          inputref={ref}
                                          value={value}
                                          error={errors.addrLine2}
                                          label='Address Line 2'
                                          maxLength={150}
                                          type='textarea'
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-lg-6" data-select2-id={1}>
                              <div className="form-group" data-select2-id={15}>
                                 <label className="form-label">State <span className="text-danger">*</span></label>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="state"
                                    render={({ ref, value, onChange, ...rest }) => (
                                       <SelectInput
                                          inputValue={search}
                                          error={errors.state}
                                          inputref={ref}
                                          value={value}
                                          onChange={(e) => { onChange(e); resetCity(); }}
                                          options={stateList}
                                          {...rest}
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                           <div className="col-lg-6" data-select2-id={1}>
                              <div className="form-group" data-select2-id={15} >
                                 <label className="form-label">City <span className="text-danger">*</span></label>
                                 <CreatableSelect
                                    name="city"
                                    ref={refe}
                                    onBlur={() => setOpen(false)}
                                    menuIsOpen={isOpen}
                                    inputValue={search}
                                    onInputChange={typeAndSearch}
                                    onChange={handleSelect}
                                    options={cityList}
                                    createOptionPosition='first'
                                    cacheOptions
                                    value={selectedCity}
                                    blurInputOnSelect
                                    isLoading={loading}
                                    allowCreateWhileLoading={false}
                                    placeholder={'City'}
                                    isDisabled={!selectedState || !selectedState.value}
                                 />
                                 <small className="text-muted font-weight-bold float-right mt-1">(Input at least 3 characters)</small>
                              </div>
                           </div>
                        </div>

                        <div className="row mt-2">
                           <div className="col-lg-6" data-select2-id={1}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="phone"
                                    render={({ ref, value, ...props }) => (
                                       <MaskInput
                                          mask="(999) 999-9999"
                                          maskPlaceholder="Phone"
                                          inputref={ref}
                                          value={value}
                                          error={errors.phone}
                                          label='Phone'
                                          type={'number'}
                                          required
                                          {...props}
                                          disabled
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                           <div className="col-lg-6" data-select2-id={1}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="zipCode"
                                    render={({ ref, value, ...props }) => (
                                       <TextInput
                                          inputref={ref}
                                          value={value}
                                          error={errors.zipCode}
                                          label='Zip'
                                          placeholder="Zip"
                                          maxLength={6}
                                          required
                                          type='text'
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-lg-6" data-select2-id={1}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="liscenseNo"
                                    render={({ ref, value, ...props }) => (
                                       <TextInput
                                          inputref={ref}
                                          value={value}
                                          error={errors.liscenseNo}
                                          label='License No.'
                                          maxLength={200}
                                          type='text'
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>

                           <div className="col-lg-6">
                                 <div className="form-group" data-select2-id={15}>
                                    <label>Administrator </label><span className="text-danger">*</span>
                                    <Controller
                                       defaultValue={''}
                                       control={control}
                                       name="staff"
                                       render={({ ref, value, ...rest }) => (
                                          <SelectInput
                                             inputref={ref}
                                             value={value}
                                             placeholder='Select staff'
                                             required
                                             options={users}
                                             {...rest}
                                          />
                                       )}
                                    />
                                 </div>
                              </div>
                        </div>

                        <ReadCheckHOC module={MODULES.FACILITY} operation={props.facId ? 'update' : 'create'} isView={props.isView}>
                           <button type="submit" className="btn btn-success waves-effect waves-light mt-3">Save Details</button>
                        </ReadCheckHOC>

                        {isWizard === true && (
                           <button  type="button" style={{paddingRight:"15px important!"}} className="btn btn-success waves-effect waves-light mt-3 mr-2" onClick={()=>{skiptonextstep()
                           props.setforceExit(true)
                           }}>Skip</button>
                        )}

                     </div>
                  </div>
               </div>
            </div>
         </fieldset>
      </form>
      </>
   )
                        
}  
)


export default FacilityForm