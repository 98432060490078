import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ACTIONS, MODULES, STORE_ID } from '../../config/config';
import MedicationForm from '../../components/resident/manage-resident/medicationForm';
import { useMedicationApi } from '../../hooks/api/medcationApiHook';
import { timeName } from '../../components/form-elements/functions';
import MedWitnessForm from '../../components/resident/manage-resident/medWitnessForm';
import MedWitnessEditForm from '../../components/resident/manage-resident/medWitnessEditForm';
import { encryptId, formatPhone } from '../../components/form-elements/functions';
import Swal from 'sweetalert2';
import moment from "moment";
import 'moment-timezone';
import { useResidentApi } from '../../hooks/api/residentApiHook';
import { useUserSelector } from '../../hooks/selectors/userSelector';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const ResMedicationList = props => {
  // console.log(props)
  const [modal, showModal] = useState(false);
  const [witnessModal, showWitnessModal] = useState(false);
  const [witnessModalData, setWitnessModalData] = useState({});
  const [details, setDetails] = useState({});
  const [alert, setAlert] = useState(null);
  const [apiList, setApiList] = useState([]);
  const [search, setSearch] = useState('');
  const [isWizard, setWizard] = useState(props.isWizard);
  const [witnessModalData1, setWitnessModalData1] = useState({});
  const [witnessModal1, showWitnessModal1] = useState(false);
  const [companyId, setCompanyId] = useState();

  const medicationApi = useMedicationApi();
  const history = useHistory();
  // const dispatch = useDispatch()
  const residentApi = useResidentApi()
  const user = useUserSelector()


  useEffect(() => {
    // console.log(user);
    if (props.resId) {
      getList();
      getCompanyIds()
      window.scrollTo(0, 0);
    }
  }, [props.resId]);

  const getList = (search = '') => {

    medicationApi.listMedication(
      { residentId: props.resId, search },
      (message, resp) => {
        resp?.sort((a, b) => (a.name.toLowerCase().charCodeAt(0) - b.name.toLowerCase().charCodeAt(0)))
        let temp =
          resp.length &&
          resp.map((item) => {
            var current = moment().format('YYYY-MM-DD HH:mm:ss');
            var tomorrow = new Date(item.createdDate);
            tomorrow.setDate(tomorrow.getDate() + 1);
            // console.log(tomorrow,current)
            return {
              ...item,
              createdDate: moment.utc(item.createdDate).local().format('YYYY-MM-DD HH:mm:ss'),
              current: current,
              tomorrow: moment.utc(tomorrow).local().format('YYYY-MM-DD HH:mm:ss')
            };
          });
        // console.log(temp)
        // return item
        // })
        setApiList(temp);
      },
      message => {
        toast.error(message);
      },
    );
  };

  const toggle = () => {
    if (modal) {
      showModal(false);
      setDetails({});
    } else {
      showModal(true);
    }
  };

  const toggleWitnessModal = (evt, data = {}) => {
    // console.log('datadatadatadatadatadata', data);
    evt && evt.preventDefault();
    setWitnessModalData(data);
    showWitnessModal(!witnessModal);
  };

  const toggleWitnessEditModal = (evt, data = {}) => {
    // console.log('datadatadatadatadatadata', data);
    evt && evt.preventDefault();
    setWitnessModalData1(data);
    showWitnessModal1(!witnessModal1);
  };

  const editData = id => {
    let data = apiList.find(item => item.id === id);
    if (data) {
      setDetails(data);
      toggle();
    }
  };

  const showConfirm = id => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Continue!"
        confirmBtnBsStyle="danger cancel-padding"
        title="Discontinue Medicaton?"
        onConfirm={() => deleteMedFunc(id)}
        onCancel={() => setAlert(null)}
        focusCancelBtn={false}>
        Are you sure?
      </SweetAlert>,
    );
  };

  const deleteMedFunc = id => {
    setAlert(null);
    medicationApi.deleteMedication(
      { id },
      (message, resp) => {
        toast.success('Medication discontinued successfully');
        getList();
      },
      message => {
        toast.error(message);
      },
    );
  };

  const showDiscontinueConfirm = data => {
    // console.log("showDiscontinueConfirm", data);
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Continue!"
        confirmBtnBsStyle="danger cancel-padding"
        title="Discontinue Medicaton?"
        onConfirm={() => discontinueMedication(data)}
        onCancel={() => setAlert(null)}
        focusCancelBtn={false}>
        Are you sure, want to Discontinue this Medicine?
        Your admin will be notified for approval
      </SweetAlert>,
    );
  };

  const discontinueMedication = data => {
    // console.log("discontinueMedication", data);
    setAlert(null)
    medicationApi.deleteMedication(
      data,
      (message, resp) => {
        toggleWitnessModal(false);
        toast.success('Medication discontinued successfully');
        getList();
      },
      message => {
        toast.error(message);
      },
    );
  };

  const discontinueMedicationEdit = data => {
    medicationApi.updatesMedications(
      data,
      (message, resp) => {
        toggleWitnessEditModal(false);
        toast.success('Medication Updated successfully');
        getList();
      },
      message => {
        toast.error(message);
      },
    );
  };

  const submitMedicine = () => {
    getList();
    toggle();
  };

  const cancelSearch = () => {
    getList();
    setSearch('');
  };

  const handleSearch = () => {
    if (search === '') {
      toast.warn('No search value given');
    } else {
      getList(search);
    }
  };

  const skiptonextstep = () => {
    history.push('/dashboard');
  };

  const thirdPartyApiCall = (id) => {
    let obj = apiList?.find(item => item.id === id)
    Swal.fire({
      title: `Request a refill?`,
      text: 'Are you sure?',
      icon: 'warning',
      confirmButtonText: 'Continue!',
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: false,
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      reverseButtons: true,
      //  cancelButtonColor: '#556ee6',
      buttons: {
        cancel: { text: 'Ubah', className: 'sweet-warning' }
      },
      //  confirmBtnBsStyle:"danger cancel-padding"
    }).then(function (result) {
      // Redirect the user
      if (result.isConfirmed) {
        thirdPApiCalling(obj)
      }
      else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    });

  }

  const thirdPApiCalling = (obj) => {
    // console.log('companyId', companyId, obj, STORE_ID)
    // let params={StoreID: `1`, DocSignature:null, MessageHashCode:null, PDFFile: "", Patient:{FirstName:companyId?.firstName, LastName:companyId?.lastName,Sex: companyId?.gender, Patientstreet: companyId?.facility?.addrLine1, MI: null, DOB: companyId?.birthDate, Patientcity: null, Patientstate:null, Patientzip:null, PatientPhone:companyId?.phone || null, SSN: companyId?.ssn || null}, Doctor:{DoctorLastName: obj?.physician,DoctorFirstNa:obj?.physician, Doctorstreet: null, Doctorcity: null, Doctorstate: null, Doctorzip:null, DoctorDea: null, DoctorNpi:null, DoctorPhone:null}, RxClaim: {DrugName:obj?.name, Qty:obj?.prn_qty.toString(), Refills: obj?.refillis.toString(), Instructions: obj?.spDirections, DrugNDC:null, DateWritten:null, Daw:null, DaysSupply: null, Notes: obj?.spDirections, RequestedBy: null, Insurance: {Bin: null, PCN: null, CardNumber: null, GroupNumber: null}}}
    let params = { StoreID: STORE_ID, DocSignature: null, MessageHashCode: null, PDFFile: "", Patient: { FirstName: companyId?.firstName, LastName: companyId?.lastName, Sex: companyId?.gender, Patientstreet: companyId?.facility?.addrLine1, MI: null, DOB: companyId?.birthDate, Patientcity: null, Patientstate: null, Patientzip: null, PatientPhone: companyId?.phone || null, SSN: companyId?.ssn || null }, Doctor: { DoctorLastName: obj?.physician, DoctorFirstName: obj?.physician, Doctorstreet: null, Doctorcity: null, Doctorstate: null, Doctorzip: null, DoctorDea: null, DoctorNpi: null, DoctorPhone: null }, RxClaim: { DrugName: obj?.name, Qty: obj?.prn_qty.toString(), Refills: obj?.refillis.toString(), Instructions: obj?.spDirections, DrugNDC: null, DateWritten: null, Daw: null, DaysSupply: null, Notes: obj?.spDirections, RequestedBy: null }, Insurance: { Bin: null, PCN: null, CardNumber: null, GroupNumber: null } }
    residentApi.thirdPartyPRNSubmit(params,
      (message, resp) => {
        toast.success('Medication submitted successfully')
        if (resp?.data?.ID) {
          updateRxStatus(obj, resp?.data?.ID)
        }
        // history.push('/resident-list')
      }, (message) => {
        toast.error(message)
      })
  }

  const updateRxStatus = (data, rxId) => {
    // console.log('dataaasss', data)
    let params = {}
    params['RxNumber'] = rxId
    params['RxStatus'] = 0
    params['id'] = data?.id
    params['residentId'] = props.resId
    medicationApi.updateRxStatus(
      params,
      (message, resp) => {
        // toggleWitnessEditModal(false);
        // toast.success('Medication Updated successfully');
        getList();
      },
      message => {
        toast.error(message);
      },
    );
  };

  const getCompanyIds = () => {
    const id = props.resId
    residentApi.residentDetails({ id },
      (message, resp) => {
        // console.log('resp?.companyId', resp?.companyId)
        setCompanyId(resp)

      }, (message, resp) => {
        if (resp.status === 404) {
          toast.error('No such resident found')
          setTimeout(() => {
            history.replace('/resident-list')
          }, 500);
        }
        else {
          toast.error(message)
        }
      })
  }

  return (
    <>
      {alert}
      <div className="mt-3 p-3">
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-outline-dark accordian-add-btn"
              onClick={() => showModal(true)}>
              {' '}
              <i className="fas fa-plus" />
              &nbsp;Add New
            </button>
          <button
              type="button"
              className="btn btn-outline-dark accordian-add-btn mr-2"
              onClick={() => history.push(`/622-report/${encryptId(props.resId)}?tab=medication`)}>
              {' '}
              <i className="fa fa-print" />
              &nbsp;Print
            </button>
          </div>
          <div className="col-12 mb-4">
            <div className="d-flex">
              <input
                className="form-control mr-2"
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder="Search"
              />
              <button
                className="btn btn-outline-success mr-2"
                onClick={handleSearch}>
                <i className="bx bx-search-alt" />
              </button>
              <button
                className="btn btn-outline-danger mr-1"
                onClick={cancelSearch}>
                <i className="bx bx-x-circle" />
              </button>
            </div>
          </div>
        </div>


        <div className="card border shadow-none">
          <div className='card-header'><h5>Routine</h5></div>
          <div className='card-body p-0'>
            <div className="table-responsive">
              <table className="table table-striped table-condensed table-bordered w-100">
                <thead>
                  <tr>
                    <th style={{ minWidth: '120px' }}>Name</th>
                    <th>Rx Number</th>
                    <th>Is New Medication</th>
                    {/* <th>Strength</th> */}
                    {/* <th>Units</th> */}
                    <th style={{ minWidth: '150px' }}>Directions</th>
                    <th style={{ minWidth: '110px' }}>Frequency</th>
                    <th style={{ minWidth: '100px' }}>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {apiList.length &&
                    apiList.filter(
                      item =>
                        item?.freq && (item?.freq[0]?.time == 'Am' || item?.freq[0]?.time == 'Pm' ||
                          item?.freq[0]?.time == 'Evening' ||
                          item?.freq[0]?.time == 'Bed'),
                    ).length ? (
                    apiList
                      .filter(
                        item =>
                          item?.freq && item?.freq?.length != 0 && (item?.freq[0]?.time == 'Am' || item?.freq[0]?.time == 'Pm' ||
                            item?.freq[0]?.time == 'Evening' ||
                            item?.freq[0]?.time == 'Bed'),
                      )
                      .map((item, i) => (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>{item.prescription}</td>
                          <td className="text-danger font-weight-bolder">{((item.createdDate <= item.current) && (item.current <= item.tomorrow)) ? 'Yes' : ''}</td>
                          {/* <td>{item.strength}</td> */}
                          {/* <td>{item.units}</td> */}
                          {item.spDirections.length > 70 ? <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>
                                {item.spDirections.length > 70 ? item.spDirections : ''}
                              </Tooltip>
                            )}
                            placement="bottom"
                          ><td>{!item.spDirections.length
                            ? 'N/A'
                            : item.spDirections.length > 70
                              ? `${item.spDirections.substr(0, 70)}...`
                              : item.spDirections}</td></OverlayTrigger> : <td>{!item.spDirections.length
                                ? 'N/A'
                                : item.spDirections}</td>}
                          <td>
                            {item.freq.length
                              ? item.freq
                                .filter(item => item.time != 'Pass')
                                .map(item => timeName(item.time))
                                .join(', ')
                              : 'N/A'}
                          </td>
                          <td>
                            {(item.approvedDate === '' || item.approval[0].status == 'Rejected') && (
                              <i
                                onClick={() => editData(item.id)}
                                className="fa fa-edit mr-3 cursor-pointer"
                                data-toggle="tooltip"
                                title="Edit medication"
                              />
                            )}
                            {item.approvedDate === null && item.approval[0].status == 'Pending' && (
                              <i className={"fa fa-ban inactive-dc-button tooltipss"} onClick={e => user?.roles == 'user' ? toggleWitnessEditModal(e, item) : {}}>
                                <ol class="tooltiptextss email-pos-chng">
                                  <span class="editclss">{user?.roles == 'user' ? 'Edit' : ''}</span>
                                  {item.approval.length
                                    ? item.approval.filter((items, index) => (items.status == 'Pending')).map((items, index) => (
                                      <li>
                                        {items?.witness?.firstName + ' ' + items?.witness?.lastName[0].toUpperCase() + ' ' + '(' + items?.witness?.email + ')'}
                                      </li>
                                    ))
                                    : 'N/A'}
                                </ol>
                              </i>
                            )}
                            {(item.approvedDate === '' || item.approval[0].status == 'Rejected') && (
                              <i
                                onClick={e => toggleWitnessModal(e, item)}
                                className="fa fa-ban cursor-pointer text-danger"
                                data-toggle="tooltip"
                                title="Discontinue medication"
                              />
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td className="no-td" colSpan="3">
                        No results found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div className="card border shadow-none">
          <div className='card-header'><h5>PRN</h5></div>
          <div className='card-body p-0'>
            <div className="table-responsive">
              <table className="table table-striped table-condensed table-bordered w-100">
                <thead>
                  <tr>
                    <th style={{ minWidth: '120px' }}>Name</th>
                    <th>Rx Number</th>
                    <th>Is New Medication</th>
                    {/* <th>Strength</th> */}
                    {/* <th>Units</th> */}
                    <th style={{ minWidth: '150px' }}>Directions</th>
                    <th style={{ minWidth: '100px' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {apiList.length &&
                    apiList.filter(obj => (obj?.freq?.length && (obj?.freq[0]?.time == 'Pnr') || (obj?.freq?.length && obj?.freq[0]?.time === 'Pnr'))).length ? (
                    apiList
                      .filter(ob => (ob?.freq?.length && (ob?.freq[0]?.time == 'Pnr') || (ob?.freq?.length && ob?.freq[0]?.time === 'Pnr')))
                      .map((items, i) => (
                        <tr key={items.id}>

                          <td>{items.name}</td>
                          <td>{items.prescription}</td>
                          <td className="text-danger font-weight-bolder">{((items.createdDate <= items.current) && (items.current <= items.tomorrow)) ? 'Yes' : ''}</td>
                          {/* <td>{items.strength}</td> */}
                          {/* <td>{items.units}</td> */}
                          {items.spDirections.length > 65 ? <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>
                                {items.spDirections.length > 65 ? items.spDirections : ''}
                              </Tooltip>
                            )}
                            placement="bottom"
                          ><td>{!items.spDirections.length
                            ? 'N/A'
                            : items.spDirections.length > 65
                              ? `${items.spDirections.substr(0, 65)}...`
                              : items.spDirections}</td></OverlayTrigger> : <td>{!items.spDirections.length
                                ? 'N/A'
                                : items.spDirections}</td>}
                          <td>
                            {(items.approvedDate === '' || items.approval[0].status == 'Rejected') && (
                              <i
                                onClick={() => editData(items.id)}
                                className="fa fa-edit mr-3 cursor-pointer"
                                data-toggle="tooltip"
                                title="Edit medication"
                              />
                            )}

                            <i onClick={() => user?.company?.thirdPartyApiFacility && items?.pharmacy == 'Senior Wellness Solutions' && items?.thirdPartyMedical?.filter((r) => r?.RxStatus === 0)?.length < 1 ? thirdPartyApiCall(items.id) : ''} className={"fa fa-paper-plane mr-3 " + (user && user?.company && user?.company?.thirdPartyApiFacility && items?.pharmacy == 'Senior Wellness Solutions' ?
                              ((items.approvedDate === '' || !items.approvedDate) && items?.thirdPartyMedical?.length > 0 &&
                                items?.thirdPartyMedical.filter((r) => r.RxStatus === 0).length ? " inactive-dc-button" : 'cursor-pointer') : " inactive-mc-button")} data-toggle="tooltip"
                              title={user && user?.company && user?.company?.thirdPartyApiFacility && items?.pharmacy == 'Senior Wellness Solutions' ? "Request a refill?" : (user?.company?.thirdPartyApiFacility == 0 ? "This feature is only available when using our integrated pharmacy solution. Contact us to enable" : "This resident does not use Senior Wellness Solutions as their pharmacy and requires manual medication management")} />

                            {items.approvedDate === null && items.approval[0].status == 'Pending' && (
                              <i className={"fa fa-ban inactive-dc-button tooltipss"} onClick={e => user?.roles == 'user' ? toggleWitnessEditModal(e, items) : {}}>
                                <ol class="tooltiptextss email-pos-chng">
                                  <span class="editclss">{user?.roles == 'user' ? 'Edit' : ''}</span>
                                  {items.approval.length
                                    ? items.approval.filter((items, index) => (items.status == 'Pending')).map((itemss, index) => (
                                      <li>
                                        {itemss?.witness?.firstName + ' ' + itemss?.witness?.lastName[0].toUpperCase() + ' ' + '(' + itemss?.witness?.email + ')'}
                                      </li>
                                    ))
                                    : 'N/A'}
                                </ol>
                              </i>
                            )}

                            {(items.approvedDate === '' || items.approval[0].status == 'Rejected') && (
                              <i
                                onClick={e => toggleWitnessModal(e, items)}
                                className={"fa fa-ban cursor-pointer text-danger"}
                                data-toggle="tooltip"
                                title="Discontinue medication"
                              />
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td className="no-td" colSpan="3">
                        No results found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>


        {/* <div className="card border shadow-none">
          <div className='card-header'><h5>Treatment</h5></div>
          <div className='card-body p-0'>
            <div className="table-responsive">
              <table className="table table-striped table-condensed table-bordered w-100">
                <thead>
                  <tr>
                    <th style={{ minWidth: '120px' }}>Name</th>
                  <th>Rx Number</th>
                    <th>Strength</th>
                    <th>Units</th>
                    <th style={{ minWidth: '150px' }}>Directions</th>
                    <th style={{ minWidth: '100px' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {apiList.length &&
                    apiList.filter(obj => obj?.freq[0]?.time === 'Treatment').length ? (
                    apiList
                      .filter(ob => ob?.freq[0]?.time === 'Treatment')
                      .map((items, i) => (
                        <tr key={items.id}>
                        <td>{items.name}</td>
                            <td>{items.prescription}</td>
                          <td>{items.strength}</td>
                          <td>{items.units}</td>
                          {/* <td className={items.spDirections.length > 65 ? 'tooltip-cursor' : ''} data-toggle="tooltip" title={items.spDirections.length > 65 ? items.spDirections : ''}>
                            {!items.spDirections.length
                              ? 'N/A'
                              : items.spDirections.length > 65
                                ? `${items.spDirections.substr(0, 65)}...`
                                : items.spDirections}
                          </td> */}
        {/* {items.spDirections.length > 65 ? <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>
                                {items.spDirections.length > 65 ? items.spDirections : ''}
                              </Tooltip>
                            )}
                            placement="bottom"
                          ><td>{!items.spDirections.length
                            ? 'N/A'
                            : items.spDirections.length > 65
                              ? `${items.spDirections.substr(0, 65)}...`
                              : items.spDirections}</td></OverlayTrigger> : <td>{!items.spDirections.length
                                ? 'N/A'
                                : items.spDirections}</td>}
                          <td>
                            {(items.approvedDate === '' || items.approval[0].status == 'Rejected') && (
                              <i
                                onClick={() => editData(items.id)}
                                className="fa fa-edit mr-3 cursor-pointer"
                                data-toggle="tooltip"
                                title="Edit medication"
                              />
                            )} 

                            <i onClick={() => user?.company?.thirdPartyApiFacility && items?.pharmacy == 'Senior Wellness Solutions' && items?.thirdPartyMedical?.filter((r) => r?.RxStatus === 0)?.length < 1 ? thirdPartyApiCall(items.id) : ''} className={"fa fa-paper-plane mr-3 " + (user && user?.company && user?.company?.thirdPartyApiFacility && items?.pharmacy == 'Senior Wellness Solutions' ?
                              ((items.approvedDate === '' || !items.approvedDate) && items?.thirdPartyMedical?.length > 0 &&
                                items?.thirdPartyMedical.filter((r) => r.RxStatus === 0).length ? " inactive-dc-button" : 'cursor-pointer') : " inactive-mc-button")} data-toggle="tooltip"
                              title={user && user?.company && user?.company?.thirdPartyApiFacility && items?.pharmacy == 'Senior Wellness Solutions' ? "Request a refill?"  : (user?.company?.thirdPartyApiFacility == 0 ? "This feature is only available when using our integrated pharmacy solution. Contact us to enable" : "This resident does not use Senior Wellness Solutions as their pharmacy and requires manual medication management")} />

                            {items.approvedDate === null && items.approval[0].status == 'Pending' && (
                              <i className={"fa fa-ban inactive-dc-button tooltipss"} onClick={e => user?.roles == 'user' ? toggleWitnessEditModal(e, items) : {}}>
                                <ol class="tooltiptextss email-pos-chng">
                                  <span class="editclss">{user?.roles == 'user' ? 'Edit' : ''}</span>
                                  {items.approval.length
                                    ? items.approval.filter((items, index) => (items.status == 'Pending')).map((itemss, index) => (
                                      <li>
                                        {itemss?.witness?.firstName + ' ' + itemss?.witness?.lastName[0].toUpperCase() + ' ' + '(' + itemss?.witness?.email + ')'}
                                      </li>
                                    ))
                                    : 'N/A'}
                                </ol>
                              </i>
                            )}
                            {(items.approvedDate === '' || items.approval[0].status == 'Rejected') && (
                              <i
                                onClick={e => toggleWitnessModal(e, items)}
                                className="fa fa-ban cursor-pointer text-danger"
                                data-toggle="tooltip"
                                title="Discontinue medication"
                              />
                            )}
                            {console.log('show all items', items)}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td className="no-td" colSpan="3">
                        No results found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div> */}

        <div className="card border shadow-none">
          <div className='card-header'><h5>Comfort Kit</h5></div>
          <div className='card-body p-0'>
            <div className="table-responsive">
              <table className="table table-striped table-condensed table-bordered w-100">
                <thead>
                  <tr>
                    <th style={{ minWidth: '120px' }}>Name</th>
                    <th>Rx Number</th>
                    <th>Is New Medication</th>
                    {/* <th>Strength</th> */}
                    {/* <th>Units</th> */}
                    <th style={{ minWidth: '150px' }}>Directions</th>
                    <th style={{ minWidth: '100px' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {apiList.length &&
                    apiList.filter(obj => obj?.freq[0]?.time === 'CmfKit').length ? (
                    apiList
                      .filter(ob => ob?.freq[0]?.time === 'CmfKit')
                      .map((items, i) => (
                        <tr key={items.id}>
                          <td>{items.name}</td>
                          <td>{items.prescription}</td>
                          <td className="text-danger font-weight-bolder">{((items.createdDate <= items.current) && (items.current <= items.tomorrow)) ? 'Yes' : ''}</td>
                          {/* <td>{items.strength}</td> */}
                          {/* <td>{items.units}</td> */}
                          {items.spDirections.length > 65 ? <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>
                                {items.spDirections.length > 65 ? items.spDirections : ''}
                              </Tooltip>
                            )}
                            placement="bottom"
                          ><td>{!items.spDirections.length
                            ? 'N/A'
                            : items.spDirections.length > 65
                              ? `${items.spDirections.substr(0, 65)}...`
                              : items.spDirections}</td></OverlayTrigger> : <td>{!items.spDirections.length
                                ? 'N/A'
                                : items.spDirections}</td>}
                          <td>
                            {(items.approvedDate === '' || items.approval[0].status == 'Rejected') && (
                              <i
                                onClick={() => editData(items.id)}
                                className="fa fa-edit mr-3 cursor-pointer"
                                data-toggle="tooltip"
                                title="Edit medication"
                              />
                            )}

                            {items.approvedDate === null && items.approval[0].status == 'Pending' && (
                              <i className={"fa fa-ban inactive-dc-button tooltipss"} onClick={e => user?.roles == 'user' ? toggleWitnessEditModal(e, items) : {}}>
                                <ol class="tooltiptextss email-pos-chng">
                                  <span class="editclss">{user?.roles == 'user' ? 'Edit' : ''}</span>
                                  {items.approval.length
                                    ? items.approval.filter((items, index) => (items.status == 'Pending')).map((itemss, index) => (
                                      <li>
                                        {itemss?.witness?.firstName + ' ' + itemss?.witness?.lastName[0].toUpperCase() + ' ' + '(' + itemss?.witness?.email + ')'}
                                      </li>
                                    ))
                                    : 'N/A'}
                                </ol>
                              </i>
                            )}

                            {(items.approvedDate === '' || items.approval[0].status == 'Rejected') && (
                              <i
                                onClick={e => toggleWitnessModal(e, items)}
                                className="fa fa-ban cursor-pointer text-danger"
                                data-toggle="tooltip"
                                title="Discontinue medication"
                              />
                            )}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td className="no-td" colSpan="3">
                        No results found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {user?.company?.thirdPartyApiFacility === 1 && <div className="card border shadow-none">
          <div className='card-header'><h5>Without Frequency</h5></div>
          <div className='card-body p-0'>
            <div className="table-responsive">
              <table className="table table-striped table-condensed table-bordered w-100">
                <thead>
                  <tr>
                    <th style={{ minWidth: '120px' }}>Name</th>
                    <th>Rx Number</th>
                    <th>Is New Medication</th>
                    {/* <th>Strength</th> */}
                    {/* <th>Units</th> */}
                    <th style={{ minWidth: '150px' }}>Directions</th>
                    <th style={{ minWidth: '110px' }}>Frequency</th>
                    <th style={{ minWidth: '100px' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {apiList.length &&
                    apiList.filter(
                      item =>
                        item?.freq ? (item?.freq && item?.freq[0]?.time !== 'CmfKit' &&
                          item?.freq[0]?.time !== 'Treatment' &&
                          item?.freq[0]?.time !== 'Pnr' &&
                          item?.freq[0]?.time !== 'Am' &&
                          item?.freq[0]?.time !== 'Pm' &&
                          item?.freq[0]?.time !== 'Evening' &&
                          item?.freq[0]?.time !== 'Bed') : []
                    ).length
                    ? (
                      apiList
                        .filter(
                          item =>
                            item?.freq ? (item?.freq && item?.freq[0]?.time !== 'CmfKit' &&
                              item?.freq[0]?.time !== 'Treatment' &&
                              item?.freq[0]?.time !== 'Pnr' &&
                              item?.freq[0]?.time !== 'Am' &&
                              item?.freq[0]?.time !== 'Pm' &&
                              item?.freq[0]?.time !== 'Evening' &&
                              item?.freq[0]?.time !== 'Bed') : []
                        )
                        .map((item, i) => (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.prescription}</td>
                            <td className="text-danger font-weight-bolder">{((item.createdDate <= item.current) && (item.current <= item.tomorrow)) ? 'Yes' : ''}</td>
                            {/* <td>{item.strength}</td> */}
                            {/* <td>{item.units}</td> */}
                            {item.spDirections.length > 70 ? <OverlayTrigger
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  {item.spDirections.length > 70 ? item.spDirections : ''}
                                </Tooltip>
                              )}
                              placement="bottom"
                            ><td>{!item.spDirections.length
                              ? 'N/A'
                              : item.spDirections.length > 70
                                ? `${item.spDirections.substr(0, 70)}...`
                                : item.spDirections}</td></OverlayTrigger> : <td>{!item.spDirections.length
                                  ? 'N/A'
                                  : item.spDirections}</td>}
                            <td>
                              {item.freq.length
                                ? item.freq
                                  .map(item => timeName(item.time))
                                : 'N/A'}
                            </td>
                            <td>
                              {(item.approvedDate === '' || item.approval[0].status == 'Rejected') && (
                                <i
                                  onClick={() => editData(item.id)}
                                  className="fa fa-edit mr-3 cursor-pointer"
                                  data-toggle="tooltip"
                                  title="Edit medication"
                                />
                              )}

                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td className="no-td" colSpan="3">
                          No results found
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>}


        <div className="d-flex justify-content-between mt-3">
          {isWizard != true && (
            <button
              className="btn btn-warning w-lg btn-lg"
              onClick={() => history.push('/resident-list')}>
              Back to List
            </button>
          )}
          {isWizard === true && (
            <button
              className="btn btn-primary w-lg btn-lg mt-0"
              onClick={() => {
                skiptonextstep();
                props.setforceExit(true);
              }}>
              Exit Wizard{' '}
            </button>
          )}
          <button
            className="btn btn-primary w-lg btn-lg mt-0"
            onClick={props.changeStep}>
            Go to Next
          </button>
        </div>
        <Modal centered isOpen={modal} size="lg">
          <ModalHeader toggle={toggle}>Medication</ModalHeader>
          <ModalBody>
            <MedicationForm
              data={details}
              module={MODULES.RESIDENT}
              submitMedicine={submitMedicine}
              resId={props.resId}
            />
          </ModalBody>
        </Modal>
        <Modal centered isOpen={witnessModal} size="lg">
          <ModalHeader toggle={e => toggleWitnessModal(e)}>
            Discontinue Medication
          </ModalHeader>
          <ModalBody className="fixed-height-modal">
            <MedWitnessForm
              data={witnessModalData}
              onSubmit={user?.roles == 'staff' ? showDiscontinueConfirm : discontinueMedication}
              facilityId={props.facilityId}
            /> {/* onSubmit={discontinueMedication} */}
          </ModalBody>
        </Modal>
        <Modal centered isOpen={witnessModal1} size="lg">
          <ModalHeader toggle={e => toggleWitnessEditModal(e)}>
            Discontinue Medication
          </ModalHeader>
          <ModalBody className="fixed-height-modal">
            <MedWitnessEditForm
              data={witnessModalData1}
              onSubmit={discontinueMedicationEdit}
            />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default ResMedicationList;
