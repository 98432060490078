import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import {
  encryptId,
  makePhotoLink,
  timeName,
} from '../../components/form-elements/functions';
import {
  ACTIONS,
  MODULES,
  NO_IMAGE,
  OPERATIONS,
  PER_PAGE,
  SITE_URL,
} from '../../config/config';
import moment from 'moment';
import 'moment-timezone';
import { useMedPassApi } from '../../hooks/api/medpassApiHook';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PassMedForm from '../../components/pass-meds/passMedForm';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import RoleCheckHoc from '../../hoc/roleCheckHoc';
import LazyImage from '../../components/form-elements/lazyImage';
import { useResidentApi } from '../../hooks/api/residentApiHook';
import ReactCalendar from 'react-calendar';

const MedsListView = props => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [modal, showModal] = useState(false);
  const [details, setDetails] = useState({});
  const [datas, setDatas] = useState([]);
  const [prevDate, setPreviousDate] = useState('');
  const [nextDate, setNextDate] = useState('');
  const [today, setToday] = useState('');

  const [currentDate, setCurrentDate] = useState(moment());
  const [showCalendar, setShowCalendar] = useState(false);

  const medPassApi = useMedPassApi();
  const history = useHistory();
  const dispatch = useDispatch();
  const activePage = useSelector(state => state.extras && state.extras.tabPage);
  const residentApi = useResidentApi();

  useEffect(() => {
    console.log('props.time', props.time, activePage)
    if (props?.time && props?.time == 'Pnr') {
      getList(activePage);
      //    setTimeout(() => {
      //    getList1(activePage)
      // }, 2000);
    } else {
      getList(activePage);
    }
  }, []);

  const getList = (page = 1, search = '', from = '', to = '') => {
    console.log('currentDate',from, search) ;
    
    if (!from && !to) {
      from = moment(moment(new Date()).format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm')
      to = moment(moment(new Date()).format('YYYY-MM-DD 23:59:59')).utc().format('YYYY-MM-DD HH:mm')
    }else{
      from = moment(moment(from).format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm')
      to = moment(moment(to).format('YYYY-MM-DD 23:59:59')).utc().format('YYYY-MM-DD HH:mm')
    }
    // console.log(from, to)
    let params = {
      resName: search,
      passtype: props.time,
      from: from,
      to: to,
      limit: 9999999,//PER_PAGE,
      page,
    };

    medPassApi.getPatientList(
      params,
      (message, resp) => {
        if (props?.time && props?.time == 'Pnr') {
          getList2(resp);
        } else {
          // console.log('resp', resp);
          let withoutDouplicateItem = resp?.data?.filter(
            (test, index, array) =>
              index ===
              array.findIndex(
                findTest =>
                  findTest?.resident_firstName === test?.resident_firstName &&
                  findTest?.resident_lastName === test?.resident_lastName &&
                  moment(findTest?.resident_birthDate).format('YYYY-MM-DD') === moment(test?.resident_birthDate).format('YYYY-MM-DD'),
              ),
          );
          console.log(withoutDouplicateItem)
          setData({ data: withoutDouplicateItem });
        }
      },
      message => {
        //toast.error(message)
      },
    );
  };

  const getList2 = data => {
    // console.log('shree krishna', data)
    let params = {
      firstName: search,
      passtype: props.time,
      limit: 9999999,
      page: 1,
    };

    residentApi?.residentList(
      params,
      (message, resp) => {
        // console.log(resp)
        let temp = resp?.data?.filter(item => item?.gender === 'other');
        let temp1 = temp?.filter(item => item?.frequency_time)?.map(item => {
          return {
            resident_firstName: item?.firstName,
            resident_lastName: item?.lastName,
            ...item,
          };
        });
        let newArray = [];
        newArray = [...newArray, ...data?.data, ...temp1];
        // console.log('newArray', newArray);

        let withoutDouplicateItem = newArray?.filter(
          (test, index, array) =>
            index ===
            array.findIndex(
              findTest =>
                findTest?.resident_firstName === test?.resident_firstName &&
                findTest?.resident_lastName === test?.resident_lastName &&
                moment(findTest?.resident_birthDate).format('YYYY-MM-DD') === moment(test?.resident_birthDate).format('YYYY-MM-DD'),
            ),
        );

        // console.log('withoutDouplicateItem', withoutDouplicateItem)
        const newparams = { data: withoutDouplicateItem };
        if (newparams) {
          setData(newparams);
        }
      },
      message => {
        //toast.error(message)
      },
    );
  };

  const getList1 = (page = 1, search = '') => {
    let params = {
      firstName: search,
      passtype: props.time,
      limit: PER_PAGE,
      page,
    };

    residentApi?.residentList(
      params,
      (message, resp) => {
        setData(resp);
      },
      message => {
        //toast.error(message)
      },
    );
  };

  const handlePage = page => {
    if (activePage !== page) {
      if (props?.time && props?.time == 'Pnr') {
        getList1(page, search);
      } else {
        getList(page, search);
        dispatch({ type: ACTIONS.MEDPASS.SET_PAGE, payload: page });
      }
    }
  };

  const toggle = () => {
    if (modal) {
      showModal(false);
      setDetails({});
    } else {
      showModal(true);
    }
  };

  const cancelSearch = () => {
    if (props?.time && props?.time == 'Pnr') {
      getList(1);
      setSearch('');
    } else {
      getList(1);
      setSearch('');
      dispatch({ type: ACTIONS.MEDPASS.SET_PAGE, payload: 1 });
    }
  };

  const handleSearch = () => {
    if (search === '') {
      toast.warn('No search value given');
    } else {
      if (props?.time && props?.time == 'Pnr') {
        getList1(1, search);
      } else {
        getList(1, search);
        dispatch({ type: ACTIONS.MEDPASS.SET_PAGE, payload: 1 });
      }
    }
  };

  // const getPrevMonth = (prevDate) => {
  //   console.log(prevDate)
  //   if (prevDate) {
  //     var previous = new Date(prevDate);
  //     previous.setDate(previous.getDate() - 1);
  //     setPreviousDate(previous)
  //     console.log(previous)
  //     let from = moment(moment(previous).format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm')
  //     let to = moment(moment(previous).format('YYYY-MM-DD 23:59:59')).utc().format('YYYY-MM-DD HH:mm')
  //     console.log(from, to)
  //     setToday(previous)
  //     getList(1, '', from, to)

  //   } else {
  //     var current = moment().format('YYYY-MM-DD HH:mm:ss');
  //     var previous = new Date();
  //     previous.setDate(previous.getDate() - 1);
  //     setPreviousDate(previous)
  //     console.log(previous)
  //     let from = moment(moment(previous).format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm')
  //     let to = moment(moment(previous).format('YYYY-MM-DD 23:59:59')).utc().format('YYYY-MM-DD HH:mm')
  //     console.log(from, to)
  //     setToday(previous)
  //     getList(1, '', from, to)
  //   }
  // }
  // const getNextMonth = (nextDate) => {
  //   if (nextDate) {
  //     var tomorrow = new Date(nextDate);
  //     tomorrow.setDate(tomorrow.getDate() + 1);
  //     setPreviousDate(tomorrow)
  //     console.log(tomorrow)
  //     let from = moment(moment(tomorrow).format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm')
  //     let to = moment(moment(tomorrow).format('YYYY-MM-DD 23:59:59')).utc().format('YYYY-MM-DD HH:mm')
  //     console.log('', from, to)
  //     setToday(tomorrow)
  //     getList(1, '', from, to)
  //   } else {
  //     var tomorrow = new Date();
  //     tomorrow.setDate(tomorrow.getDate() + 1);
  //     setNextDate(tomorrow);
  //     console.log(tomorrow)
  //     setToday(tomorrow)
  //     let from = moment(moment(tomorrow).format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm')
  //     let to = moment(moment(tomorrow).format('YYYY-MM-DD 23:59:59')).utc().format('YYYY-MM-DD HH:mm')
  //     getList(1, '', from, to)
  //   }
  // }

  const getPrevDate = () => {
    const newDate = moment(currentDate).utc().subtract(1, 'days');
    setCurrentDate(newDate.local());
    getList(activePage,search, newDate.format('YYYY-MM-DD'), newDate.format('YYYY-MM-DD'));
  };

  // Function to get the next day
  const getNextDate = () => {
    const newDate = moment(currentDate).utc().add(1, 'days');
    setCurrentDate(newDate.local());
    getList(activePage,search, newDate.format('YYYY-MM-DD'), newDate.format('YYYY-MM-DD'));
  };

  const handleDateClick = () => {
    setShowCalendar(true);
  };

  const isFutureDate = moment(currentDate).add(1, 'days');// currentDate.isAfter(moment(), 'day'); 

  const handleCalendarSelect = (date) => {
    console.log(date, moment(date).utc());
    setCurrentDate(moment(date).utc().local());
    setShowCalendar(false);
  };

  return (
    <div className="mt-3 p-3">
      {(props?.time == 'Am' || props?.time == 'Pm'  || props?.time == 'Evening' || props?.time == 'Bed') &&<div className="float-left">
        <button
          data-toggle="tooltip"
          title="Previous Day"
          className="btn btn-info waves-light print-btn ml-1 mr-1"
          onClick={getPrevDate}
        >
          <i className="fa fa-caret-left" aria-hidden="true"></i>
        </button>

        <span className="current-date ml-3 mr-3 cursor-pointer" onClick={handleDateClick}>
          {currentDate.format('DD MMM YYYY')}  {/* Display formatted date */}
        </span>

        {/* <span className="current-date ml-3 mr-3" onClick={handleDateClick}>
          {moment.utc(currentDate).local().format('DD MMM YYYY')}
        </span> */}

        {showCalendar && (
          // <div className="calendar-popup align-items mt-2">
          <div className="calendar-container mt-2">
            <ReactCalendar
              onChange={handleCalendarSelect}
              value={currentDate}
              maxDate={new Date()}
              className="styled-calendar"
            />
          </div>
        )}
        <button
          data-toggle="tooltip"
          title="Next Day"
          className="btn btn-info waves-light print-btn mr-1"
          onClick={getNextDate}
          disabled={moment() < isFutureDate}
        >
          <i className="fa fa-caret-right" aria-hidden="true"></i>
        </button>
      </div>}

      <div className="float-right">
        <div className="d-flex mb-3">
          <input
            className="form-control mr-3"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Search by resident"
          />
          <button
            className="btn btn-outline-success mr-2"
            onClick={handleSearch}>
            <i className="bx bx-search-alt" />
          </button>
          <button className="btn btn-outline-danger" onClick={cancelSearch}>
            <i className="bx bx-x-circle" />
          </button>
        </div>
        {/* <button data-toggle="tooltip" title="Previous Month"
          className="btn btn-primary print-btn ml-1 mr-1"
          onClick={() => getPrevMonth(prevDate)}>
          <i class="fa fa-caret-left" aria-hidden="true"></i>
        </button>
        <button data-toggle="tooltip" title="Next Month"
          data-tooltip-id="my-tooltip" data-tooltip-content="Hello world!"
          className="btn btn-primary print-btn mr-1"
          onClick={() => getNextMonth(nextDate)}
        >
          <i class="fa fa-caret-right" data-toggle="tooltip" title="Next Month" saria-hidden="true"></i>
        </button> */}
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ width: '5%' }}></th>
              <th>Name</th>
              <th style={{ minWidth: '150px' }}>
                Status for ({timeName(props.time)})
              </th>
              <th style={{ minWidth: '150px' }}>Status for the day</th>
              <th style={{ textAlign: 'right', width: '8%' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data && data.data && data.data.length ? (
              data.data.map((item, i) => {
                return (
                  <tr key={i}>
                    {item.photo_path && (
                      <td className='p-1 text-center align-middle'>
                        <LazyImage
                          src={
                            item.photo_path
                              ? makePhotoLink(item.photo_path)
                              : ''
                          }
                          className="rounded avatar-sm"
                        />
                      </td>
                    )}
                    {item?.photo?.fullUrl && (
                      <td className='p-1 text-center align-middle'>
                        <img
                          loading="lazy"
                          src={item?.photo?.fullUrl || NO_IMAGE}
                          className="rounded avatar-sm"
                        />
                      </td>
                    )}

                    {!item?.photo_path && !item?.photo?.fullUrl && (
                      <td className='p-1 text-center align-middle'>
                        <img loading="lazy" src={NO_IMAGE} className="rounded avatar-sm" />{' '}
                      </td>
                    )}
                    {/* {props.time != 'Pnr' && */}
                    <td className='align-middle'>
                      {item?.resident_firstName || item?.firstName}{' '}
                      {item?.resident_lastName || item?.lastName}
                    </td>

                    <td className='align-middle'>
                      {props.time === 'Pnr' || props.time === 'CmfKit' ? (
                        'N/A'
                      ) : (
                        <>
                          <div className="progress" id="tooltip-container">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width: `${Math.ceil(
                                  (item.freqDispTaken / item.freqDispTotal) *
                                  100,
                                )}%`,
                              }}
                            />
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{
                                width: `${Math.ceil(
                                  (item.freqDispRefused / item.freqDispTotal) *
                                  100,
                                )}%`,
                              }}
                            />
                          </div>
                          <div className="mt-2 d-flex flex-column">
                            {item.freqDispTotal -
                              item.freqDispRefused -
                              item.freqDispTaken >
                              0 && (
                                <div className="d-flex align-items-baseline">
                                  <span
                                    className="dot"
                                    style={{
                                      backgroundColor: '#d2d1d1',
                                    }}
                                  />
                                  <span>
                                    {item.freqDispTotal -
                                      item.freqDispRefused -
                                      item.freqDispTaken}{' '}
                                    pending
                                  </span>
                                </div>
                              )}
                            {item.freqDispTaken > 0 && (
                              <div className="d-flex align-items-baseline">
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: '#34c38f',
                                  }}
                                />
                                <span>
                                  {item.freqDispTaken} already dispensed
                                </span>
                              </div>
                            )}
                            {item.freqDispRefused > 0 && (
                              <div className="d-flex align-items-baseline">
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: '#f46a6a',
                                  }}
                                />
                                <span>{item.freqDispRefused} refused</span>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </td>

                    <td className='align-middle'>
                      {props.time === 'Pnr' || props.time === 'CmfKit' ? (
                        'N/A'
                      ) : (
                        <>
                          <div className="progress" id="tooltip-container">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width: `${Math.ceil(
                                  (item.dayTaken / item.dayTotal) * 100,
                                )}%`,
                              }}
                            />
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{
                                width: `${Math.ceil(
                                  (item.dayRefused / item.dayTotal) * 100,
                                )}%`,
                              }}
                            />
                          </div>
                          <div className="mt-2 d-flex flex-column">
                            {item.dayTotal - item.dayRefused - item.dayTaken >
                              0 && (
                                <div className="d-flex align-items-baseline">
                                  <span
                                    className="dot"
                                    style={{
                                      backgroundColor: '#d2d1d1',
                                    }}
                                  />
                                  <span>
                                    {item.dayTotal -
                                      item.dayRefused -
                                      item.dayTaken}{' '}
                                    pending
                                  </span>
                                </div>
                              )}
                            {item.dayTaken > 0 && (
                              <div className="d-flex align-items-baseline">
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: '#34c38f',
                                  }}
                                />
                                <span>{item.dayTaken} already dispensed</span>
                              </div>
                            )}
                            {item.dayRefused > 0 && (
                              <div className="d-flex align-items-baseline">
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: '#f46a6a',
                                  }}
                                />
                                <span>{item.dayRefused} refused</span>
                              </div>
                            )}
                            {item.prnGiven > 0 && (
                              <div className="d-flex align-items-baseline">
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: '#f1b44c',
                                  }}
                                />
                                <span>{item.prnGiven} PRN</span>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </td>
                    <td className='align-middle text-right'>
                      <RoleCheckHoc
                        module={MODULES.PASS_MEDS}
                        operation={[
                          OPERATIONS.READ,
                          OPERATIONS.UPDATE,
                          OPERATIONS.CREATE,
                        ]}>
                        <button
                          className="btn btn-dark btn-sm"
                          onClick={() =>
                            props.time === 'Pnr'
                              ? history.push(
                                `/administer-prn/${encryptId(
                                  item?.id || item?.resident_id || item?.medication_residentId,
                                )}/${props.time}`,
                              )
                              : history.push(
                                `/dispense-meds/${encryptId(
                                  item.frequency_residentId,
                                )}/${props.time}?currentDate=${moment(currentDate).format('YYYY-MM-DD')}`,
                              )
                          }>
                          View Meds
                        </button>
                      </RoleCheckHoc>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="no-td" colSpan="3">
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="row">
        {/* <div className="col-lg-6">
               {data.total > 0 && <p>Total results: {data.total}</p>}
            </div> */}
        <div className="col-lg-6 d-flex justify-content-end">
          {data.totalPage > 1 ? (
            <Pagination
              activePage={activePage}
              itemsCountPerPage={PER_PAGE}
              totalItemsCount={data.total ? data.total : 1}
              pageRangeDisplayed={5}
              onChange={handlePage}
              innerClass="pagination"
              itemClass="paginate_button page-item"
              linkClass="page-link"
              activeClass="active"
              hideFirstLastPages={true}
              prevPageText="Previous"
              nextPageText="Next"
            />
          ) : null}
        </div>
      </div>
      <Modal centered isOpen={modal} size="lg">
        <ModalHeader toggle={toggle}>Dispense</ModalHeader>
        <ModalBody>
          <PassMedForm
            resId={details.frequency_residentId}
            type={details.frequency_time}
            photo={
              details.photo_path ? makePhotoLink(details.photo_path) : null
            }
            name={`${details.resident_firstName} ${details.resident_lastName}`}
            close={toggle}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MedsListView;
