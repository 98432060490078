import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useResidentApi() {
   const callApi = useApiCall();

   //resident

   const addResident = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.ADD_RESIDENT, data, onSuccess, onError);
   }

   const addResidentFamilyMember = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.ADD_RESIDENT_FAMILY_MEMBER, data, onSuccess, onError);
   }

   const updateResident = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.UPDATE_RESIDENT, data, onSuccess, onError);
   }

   const residentList = (data, onSuccess, onError, useLoader=true) => {
      callApi(SAGA_ACTIONS.RESIDENT.RESIDENT_LIST, data, onSuccess, onError, useLoader);
   }

   const residentListWithoutFacility = (data, onSuccess, onError, useLoader=true) => {
      callApi(SAGA_ACTIONS.RESIDENT.RESIDENT_LIST_WITHOUT_FACILITY, data, onSuccess, onError, useLoader);
   }

   const residentListByfacilityId = (data, onSuccess, onError, useLoader=true) => {
      console.log("API hookkkkkkkkkkkkkkkkkkkkkk");
      callApi(SAGA_ACTIONS.RESIDENT.RESIDENT_LIST_BY_FACILITY, data, onSuccess, onError, useLoader);
   }

   const witnessListByIncidentId = (data, onSuccess, onError, useLoader=true) => {
      console.log("API hookkkkkkkkkkkkkkkkkkkkkk");
      callApi(SAGA_ACTIONS.RESIDENT.WITNESS_LIST_BY_INCIDENT, data, onSuccess, onError, useLoader);
   }

   const residentDetails = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.GET_RESIDENT, data, onSuccess, onError);
   }

   const residentDetailsForDOB = (data, onSuccess, onError) => {
      console.log(SAGA_ACTIONS.RESIDENT.GET_RESIDENT_DOB)
      callApi(SAGA_ACTIONS.RESIDENT.GET_RESIDENT_DOB, data, onSuccess, onError);
   }

   const deleteResident = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.DELETE_RESIDENT, data, onSuccess, onError);
   }

   const activateResident = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.ACTIVATE_RESIDENT, data, onSuccess, onError);
   }

   //contact

   const addResidentContact = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.EM_CONTACT.CREATE_EM_CONTACT, data, onSuccess, onError);
   }

   const updateResidentContact = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.EM_CONTACT.UPDATE_EM_CONTACT, data, onSuccess, onError);
   }

   const deleteResidentContact = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.EM_CONTACT.DELETE_EM_CONTACT, data, onSuccess, onError);
   }

   const listResidentContact = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.EM_CONTACT.LIST_EM_CONTACT, data, onSuccess, onError);
   }

   const uploadResidentDoc = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.DOCS.UPLOAD_DOCS, data, onSuccess, onError);
   }

   //doc

   const addResidentDoc = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.DOCS.CREATE_DOCS, data, onSuccess, onError);
   }

   const deleteResidentDoc = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.DOCS.DELETE_DOCS, data, onSuccess, onError);
   }

   const listResidentDoc = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.DOCS.LIST_DOCS, data, onSuccess, onError);
   }

   const updateResidentDoc = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.DOCS.UPDATE_DOCS, data, onSuccess, onError);
   }

   //liscense

   const addResidentLisc = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.LISCENCE.CREATE_LISCENSE, data, onSuccess, onError);
   }

   const deleteResidentLisc = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.LISCENCE.DELETE_LISCNSE, data, onSuccess, onError);
   }

   const listResidentLisc = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.LISCENCE.LIST_LISCENSE, data, onSuccess, onError);
   }

   const updateResidentLisc = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.LISCENCE.UPDATE_LISCENSE, data, onSuccess, onError);
   }

   //incidents

   const addIncident = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.INCIDENT.CREATE_INCIDENT, data, onSuccess, onError);
   }

   const getIncident = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.INCIDENT.GET_INCIDENT, data, onSuccess, onError);
   }

   const listIncident = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.INCIDENT.LIST_INCIDENT, data, onSuccess, onError);
   }

   const updateIncident = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.INCIDENT.UPDATE_INCIDENT, data, onSuccess, onError);
   }

   const getIncidentTypes = (data, onSuccess, onError, useLoader=true) => {
      callApi(SAGA_ACTIONS.RESIDENT.INCIDENT.GET_INCIDENT_TYPES, data, onSuccess, onError, useLoader);
   }

   //reports

   const nineOneOne = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.NINE_ONE_ONE, data, onSuccess, onError);
   }

   const mar = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.MAR, data, onSuccess, onError);
   }

   const sixTwoTwo = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.SIX_TWO_TWO, data, onSuccess, onError);
   }

   const medicationHistory = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.MEDICATION_HISTORY, data, onSuccess, onError);
   }

   const thirdPartyPRNSubmit = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.RESIDENT.THIRD_PARTY_PRN_SUBMIT, data, onSuccess, onError);
   }

   const residentListJoinInventory = (data, onSuccess, onError, useLoader=true) => {
      callApi(SAGA_ACTIONS.RESIDENT.RESIDENT_LIST_JOIN_INVENTORY, data, onSuccess, onError, useLoader);
   }

   return {
      residentList,
      residentListByfacilityId,
      residentDetails,
      residentDetailsForDOB,
      deleteResident,
      addResident,
      addResidentFamilyMember,
      updateResident,
      addResidentContact,
      updateResidentContact,
      listResidentContact,
      deleteResidentContact,
      uploadResidentDoc,
      deleteResidentDoc,
      addResidentDoc,
      updateResidentDoc,
      listResidentDoc,
      addResidentLisc,
      updateResidentLisc,
      deleteResidentLisc,
      nineOneOne,
      listResidentLisc,
      sixTwoTwo,
      mar,
      medicationHistory,
      activateResident,
      addIncident,
      getIncident,
      listIncident,
      updateIncident,
      getIncidentTypes,
      thirdPartyPRNSubmit,
      residentListJoinInventory,
      residentListWithoutFacility,
      witnessListByIncidentId
   }
}