import { takeLatest, all } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../config/config';
import { ancmentDetails, ancmentList, createAncment, deleteAncment, getApproval, getIncidentApproval, updateAncment } from './ancment.saga';

import {
   deleteFiles,
   getCities,
   getRelations,
   getStates,
   uploadFile,
   uploadFiles,
   dashboard,
   resAlertsList,
   staffAlertsList
} from './common.saga';

import {
   addContact,
   updateContact,
   deleteContact,
   addLiscence,
   updateLiscence,
   deleteLiscence,
   uploadDocument,
   addDocument,
   updateDocument,
   deleteDocument
} from './contact.saga';

import {
   addFacility,
   adminFacilityList,
   deleteFacility,
   facilityList,
   getFacility,
   updateFacility
} from './facility.saga';

import {
   addMedicine,
   listMedication,
   typeListMedication,
   updateMedication,
   deleteMedication,
   updatesMedications,
   listMedicationForAlls,
   listMedicationForAllsInventory,
   updateRxStatus,
   getRxDetails,
   approveMedicationRx
} from './medication.saga';

import {
   getDetails,
   getPatientList,
   submitMedPass,
   dispenseList,
   getTimings,
   updateDispensedMed
} from './medpass.saga';

import {
   activateResident,
   addEmContact,
   addIncident,
   addResident,
   addResidentFamilyMember,
   createResDoc,
   createResLiscense,
   deleteEmContact,
   deleteResDoc,
   deleteResident,
   deleteResLiscense,
   getIncident,
   getIncidentTypes,
   listEmContact,
   listIncidents,
   listResDoc,
   listResLiscense,
   nineOneOne,
   residentDetails,
   residentDetailsForDOB,
   residentList,
   residentListByfacilityId,
   residentListJoinInventories,
   residentListWithoutFacilities,
   sixTwoTwo,
   mAR,
   medicationHistory,
   thirdPartyPRNSubmits,
   updateEMContact,
   updateIncident,
   updateResDoc,
   updateResident,
   updateResLiscense,
   uploadResDoc,
   witnessListByIncidentIds
} from './resident.saga';

import { addResuableContact, 
   deleteResuableContact, 
   getResuableContact, 
   resuableContactList, 
   updateResuableContact, 
   listContactTypes } from './reusableContact.saga';

import {
   addEditRole,
   assignRolePermission,
   deleteRole,
   getDefaultRole,
   permissionList,
   roleList,
   rolePermissionList,
   unassgnUserRole,
   getRoleDetails,
   permissionsListByRoleType
} from './role.saga';

import {
   createStaffMember,
   listStaffUsers,
   listWitnessUsers,
   listMedApproval,
   updateMedApproval,
   deleteMedApproval,
   icidentApprovalLists,
   updateIncidentApproval,
   getListStaffUsers,
   getListAdministratorUsers
} from './staff.saga';

import {
   loginUser,
   logout,
   getMe,
   registerUser,
   resetPassword,
   forgotPassword,
   getUser,
   getUserByRoleId,
   updateUser,
   checkToken,
   updateProfile,
   verifyResetPassToken,
   deleteUser,
   changePassword,
   getUserPermissions,
   changeUserState,
   updateUserMultipleFacility,
   getUserMultipleFacility,
   updateIncidentWitness,
} from './user.saga';

export function* sagas() {
   yield all([
      //Auth  
      takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
      takeLatest(SAGA_ACTIONS.USER.LOGOUT, logout),
      takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, resetPassword),
      takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, forgotPassword),
      takeLatest(SAGA_ACTIONS.USER.ME, getMe),
      takeLatest(SAGA_ACTIONS.USER.REGISTER, registerUser),
      takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE, updateProfile),
      takeLatest(SAGA_ACTIONS.USER.CHECK_TOKEN, checkToken),
      takeLatest(SAGA_ACTIONS.USER.VALID_PASSWORD_TOKEN, verifyResetPassToken),
      takeLatest(SAGA_ACTIONS.USER.CHANGE_PASSWORD, changePassword),
      takeLatest(SAGA_ACTIONS.USER.UPDATE_USER, updateUser),
      takeLatest(SAGA_ACTIONS.USER.UPDATE_USER_MULTIPLE_FACILITY, updateUserMultipleFacility),
      takeLatest(SAGA_ACTIONS.USER.UPDATE_INCIDENT_WITNESS, updateIncidentWitness),
      takeLatest(SAGA_ACTIONS.USER.GET_USER_MULTIPLE_FACILITY, getUserMultipleFacility),
      takeLatest(SAGA_ACTIONS.USER.GET_USER, getUser),
      takeLatest(SAGA_ACTIONS.USER.GET_USER_BY_ROLEID, getUserByRoleId),
      takeLatest(SAGA_ACTIONS.USER.USER_PERMISSIONS, getUserPermissions),
      takeLatest(SAGA_ACTIONS.USER.CHANGE_USER_STATE, changeUserState),
      takeLatest(SAGA_ACTIONS.USER.DELETE_USER, deleteUser),

      //common
      takeLatest(SAGA_ACTIONS.COMMON.UPLOAD_FILE, uploadFile),
      takeLatest(SAGA_ACTIONS.COMMON.UPLOAD_FILES, uploadFiles),
      takeLatest(SAGA_ACTIONS.COMMON.DELETE_FILES, deleteFiles),
      takeLatest(SAGA_ACTIONS.COMMON.GET_RELATIONS, getRelations),
      takeLatest(SAGA_ACTIONS.COMMON.STATE_LIST, getStates),
      takeLatest(SAGA_ACTIONS.COMMON.CITY_LIST, getCities),
      takeLatest(SAGA_ACTIONS.COMMON.RESIDENT_ALERTS_LIST, resAlertsList),
      takeLatest(SAGA_ACTIONS.COMMON.STAFF_ALERTS_LIST, staffAlertsList),
      takeLatest(SAGA_ACTIONS.COMMON.DASHBOARD, dashboard),

      //staff
      takeLatest(SAGA_ACTIONS.USER.CREATE_USER, createStaffMember),
      takeLatest(SAGA_ACTIONS.USER.LIST_USER, listStaffUsers),
      takeLatest(SAGA_ACTIONS.USER.LIST_USER_STAFF, getListStaffUsers),
      takeLatest(SAGA_ACTIONS.USER.LIST_USER_ADMINISTRATOR, getListAdministratorUsers),
      takeLatest(SAGA_ACTIONS.USER.LIST_USER_WITNESS, listWitnessUsers),

      //roles and permissions
      takeLatest(SAGA_ACTIONS.ROLES.ADD_EDIT_ROLE, addEditRole),
      takeLatest(SAGA_ACTIONS.ROLES.ROLE_LIST, roleList),
      takeLatest(SAGA_ACTIONS.ROLES.PERMISSIONS_LIST, permissionList),
      takeLatest(SAGA_ACTIONS.ROLES.PERMISSIONS_LIST_BY_ROLE_TYPE, permissionsListByRoleType),
      takeLatest(SAGA_ACTIONS.ROLES.ROLE_PERMISSION_LIST, rolePermissionList),
      takeLatest(SAGA_ACTIONS.ROLES.ASSIGN_PERMISSIONS_TO_ROLE, assignRolePermission),
      takeLatest(SAGA_ACTIONS.ROLES.GET_DEFAULT_ROLE, getDefaultRole),
      takeLatest(SAGA_ACTIONS.ROLES.UNASSIGN_USER_ROLE, unassgnUserRole),
      takeLatest(SAGA_ACTIONS.ROLES.GET_ROLE_DETAILS, getRoleDetails),
      takeLatest(SAGA_ACTIONS.ROLES.DELETE_ROLE, deleteRole),

      //contact
      takeLatest(SAGA_ACTIONS.CONTACT.ADD_CONTACT, addContact),
      takeLatest(SAGA_ACTIONS.CONTACT.UPDATE_CONTACT, updateContact),
      takeLatest(SAGA_ACTIONS.CONTACT.DELETE_CONTACT, deleteContact),

      //liscence
      takeLatest(SAGA_ACTIONS.LISCENCE.ADD_LISCENCE, addLiscence),
      takeLatest(SAGA_ACTIONS.LISCENCE.UPDATE_LISCENCE, updateLiscence),
      takeLatest(SAGA_ACTIONS.LISCENCE.DELETE_LISCENCE, deleteLiscence),

      //document
      takeLatest(SAGA_ACTIONS.DOCUMENT.UPLOAD_DOCUMENT, uploadDocument),
      takeLatest(SAGA_ACTIONS.DOCUMENT.ADD_DOCUMENT, addDocument),
      takeLatest(SAGA_ACTIONS.DOCUMENT.UPDATE_DOCUMENT, updateDocument),
      takeLatest(SAGA_ACTIONS.DOCUMENT.DELETE_DOCUMENT, deleteDocument),

      //resident
      takeLatest(SAGA_ACTIONS.RESIDENT.GET_RESIDENT, residentDetails),
      takeLatest(SAGA_ACTIONS.RESIDENT.GET_RESIDENT_DOB, residentDetailsForDOB),
      takeLatest(SAGA_ACTIONS.RESIDENT.RESIDENT_LIST, residentList),
      takeLatest(SAGA_ACTIONS.RESIDENT.RESIDENT_LIST_WITHOUT_FACILITY, residentListWithoutFacilities),
      takeLatest(SAGA_ACTIONS.RESIDENT.RESIDENT_LIST_JOIN_INVENTORY, residentListJoinInventories),
      takeLatest(SAGA_ACTIONS.RESIDENT.RESIDENT_LIST_BY_FACILITY, residentListByfacilityId),
      takeLatest(SAGA_ACTIONS.RESIDENT.WITNESS_LIST_BY_INCIDENT, witnessListByIncidentIds),
      takeLatest(SAGA_ACTIONS.RESIDENT.ADD_RESIDENT, addResident),
      takeLatest(SAGA_ACTIONS.RESIDENT.ADD_RESIDENT_FAMILY_MEMBER, addResidentFamilyMember),
      takeLatest(SAGA_ACTIONS.RESIDENT.UPDATE_RESIDENT, updateResident),
      takeLatest(SAGA_ACTIONS.RESIDENT.DELETE_RESIDENT, deleteResident),
      takeLatest(SAGA_ACTIONS.RESIDENT.ACTIVATE_RESIDENT, activateResident),
      takeLatest(SAGA_ACTIONS.RESIDENT.NINE_ONE_ONE, nineOneOne),
      takeLatest(SAGA_ACTIONS.RESIDENT.SIX_TWO_TWO, sixTwoTwo),
      takeLatest(SAGA_ACTIONS.RESIDENT.MAR, mAR),
      takeLatest(SAGA_ACTIONS.RESIDENT.MEDICATION_HISTORY, medicationHistory),
      takeLatest(SAGA_ACTIONS.RESIDENT.THIRD_PARTY_PRN_SUBMIT, thirdPartyPRNSubmits),

      //incident
      takeLatest(SAGA_ACTIONS.RESIDENT.INCIDENT.CREATE_INCIDENT, addIncident),
      takeLatest(SAGA_ACTIONS.RESIDENT.INCIDENT.LIST_INCIDENT, listIncidents),
      takeLatest(SAGA_ACTIONS.RESIDENT.INCIDENT.GET_INCIDENT, getIncident),
      takeLatest(SAGA_ACTIONS.RESIDENT.INCIDENT.UPDATE_INCIDENT, updateIncident),
      takeLatest(SAGA_ACTIONS.RESIDENT.INCIDENT.GET_INCIDENT_TYPES, getIncidentTypes),

      //emergency contact
      takeLatest(SAGA_ACTIONS.RESIDENT.EM_CONTACT.LIST_EM_CONTACT, listEmContact),
      takeLatest(SAGA_ACTIONS.RESIDENT.EM_CONTACT.CREATE_EM_CONTACT, addEmContact),
      takeLatest(SAGA_ACTIONS.RESIDENT.EM_CONTACT.UPDATE_EM_CONTACT, updateEMContact),
      takeLatest(SAGA_ACTIONS.RESIDENT.EM_CONTACT.DELETE_EM_CONTACT, deleteEmContact),
      //docs
      takeLatest(SAGA_ACTIONS.RESIDENT.DOCS.UPLOAD_DOCS, uploadResDoc),
      takeLatest(SAGA_ACTIONS.RESIDENT.DOCS.CREATE_DOCS, createResDoc),
      takeLatest(SAGA_ACTIONS.RESIDENT.DOCS.LIST_DOCS, listResDoc),
      takeLatest(SAGA_ACTIONS.RESIDENT.DOCS.UPDATE_DOCS, updateResDoc),
      takeLatest(SAGA_ACTIONS.RESIDENT.DOCS.DELETE_DOCS, deleteResDoc),
      //liscense
      takeLatest(SAGA_ACTIONS.RESIDENT.LISCENCE.CREATE_LISCENSE, createResLiscense),
      takeLatest(SAGA_ACTIONS.RESIDENT.LISCENCE.UPDATE_LISCENSE, updateResLiscense),
      takeLatest(SAGA_ACTIONS.RESIDENT.LISCENCE.DELETE_LISCNSE, deleteResLiscense),
      takeLatest(SAGA_ACTIONS.RESIDENT.LISCENCE.LIST_LISCENSE, listResLiscense),

      //facility
      takeLatest(SAGA_ACTIONS.FACILITY.FACILITY_LIST, facilityList),
      takeLatest(SAGA_ACTIONS.FACILITY.ADD_FACILITY, addFacility),
      takeLatest(SAGA_ACTIONS.FACILITY.DELETE_FACILITY, deleteFacility),
      takeLatest(SAGA_ACTIONS.FACILITY.UPDATE_FACILITY, updateFacility),
      takeLatest(SAGA_ACTIONS.FACILITY.GET_FACILITY, getFacility),
      takeLatest(SAGA_ACTIONS.FACILITY.ADMIN_FACILITY_LIST, adminFacilityList),

      //medicine
      takeLatest(SAGA_ACTIONS.MEDICATION.ADD_MEDICATION, addMedicine),
      takeLatest(SAGA_ACTIONS.MEDICATION.MEDICATION_LIST, listMedication),
      takeLatest(SAGA_ACTIONS.MEDICATION.TYPE_MEDICATION_LIST, typeListMedication),
      takeLatest(SAGA_ACTIONS.MEDICATION.UPDATE_MEDICATION, updateMedication),
      takeLatest(SAGA_ACTIONS.MEDICATION.DELETE_MEDICATION, deleteMedication),
      takeLatest(SAGA_ACTIONS.MEDICATION.UPDATES_MEDICATIONS, updatesMedications),
      takeLatest(SAGA_ACTIONS.MEDICATION.MEDICATION_LIST_FOR_ALL, listMedicationForAlls),
      takeLatest(SAGA_ACTIONS.MEDICATION.MEDICATION_LIST_FOR_ALL_INVENTORY, listMedicationForAllsInventory),
      takeLatest(SAGA_ACTIONS.MEDICATION.UPDATE_RX_STATUS, updateRxStatus),
      takeLatest(SAGA_ACTIONS.MEDICATION.GET_RX_DETAILS, getRxDetails),
      takeLatest(SAGA_ACTIONS.MEDICATION.APPROVE_MEDICATION_RX, approveMedicationRx),
      //medpass
      takeLatest(SAGA_ACTIONS.MEDPASS.GET_LIST, getPatientList),
      takeLatest(SAGA_ACTIONS.MEDPASS.GET_DETAILS, getDetails),
      takeLatest(SAGA_ACTIONS.MEDPASS.SUBMIT_MEDPASS, submitMedPass),
      takeLatest(SAGA_ACTIONS.MEDPASS.DISPENSE_LIST, dispenseList),
      takeLatest(SAGA_ACTIONS.MEDPASS.GET_TIMINGS, getTimings),
      takeLatest(SAGA_ACTIONS.MEDPASS.UPDATE_DISPENSED_MED, updateDispensedMed),

      //med approval
      takeLatest(SAGA_ACTIONS.MED_APPROVAL.MED_APPROVAL_LIST, listMedApproval),
      takeLatest(SAGA_ACTIONS.MED_APPROVAL.UPDATE_MED_APPROVAL, updateMedApproval),
      takeLatest(SAGA_ACTIONS.MED_APPROVAL.DELETE_MED_APPROVAL, deleteMedApproval),
      takeLatest(SAGA_ACTIONS.MED_APPROVAL.INCIDENT_APPROVAL_LIST, icidentApprovalLists),
      takeLatest(SAGA_ACTIONS.MED_APPROVAL.UPDATE_INCIDENT_APPROVAL, updateIncidentApproval),

      //announcement
      takeLatest(SAGA_ACTIONS.ANCMENT.CREATE_ANCMENT, createAncment),
      takeLatest(SAGA_ACTIONS.ANCMENT.LIST_ANCMENTS, ancmentList),
      takeLatest(SAGA_ACTIONS.ANCMENT.DELETE_ANCMENT, deleteAncment),
      takeLatest(SAGA_ACTIONS.ANCMENT.GET_ANCMENT, ancmentDetails),
      takeLatest(SAGA_ACTIONS.ANCMENT.UPDATE_ANCMENT, updateAncment),
      takeLatest(SAGA_ACTIONS.ANCMENT.GET_APPROVAL, getApproval),
      takeLatest(SAGA_ACTIONS.ANCMENT.GET_INCIDENT_APPROVAL, getIncidentApproval),

      //reusable contacts
      takeLatest(SAGA_ACTIONS.REUSABLE_CONTACT.CREATE_REUSABLE_CONTACT, addResuableContact),
      takeLatest(SAGA_ACTIONS.REUSABLE_CONTACT.UPDATE_REUSABLE_CONTACT, updateResuableContact),
      takeLatest(SAGA_ACTIONS.REUSABLE_CONTACT.DELETE_REUSABLE_CONTACT, deleteResuableContact),
      takeLatest(SAGA_ACTIONS.REUSABLE_CONTACT.LIST_REUSABLE_CONTACT, resuableContactList),
      takeLatest(SAGA_ACTIONS.REUSABLE_CONTACT.GET_REUSABLE_CONTACT, getResuableContact),
      takeLatest(SAGA_ACTIONS.REUSABLE_CONTACT.LIST_CONTACT_TYPES, listContactTypes),
   ]);
}