import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ScrollIntoView from './common/scrollIntoView'
import Loader from './common/loader';
import { allRoutes } from './routes/allRoutes';
import PrivateRoute from './routes/privateRoute';
import PublicRoute from './routes/publicRoute';
import Login from './container/login';
import UnAuthLayout from './hoc/unAuthLayout';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useState } from 'react';



function App() {
   const [alert, setAlert] = useState(null)

   const getUserConfirmation = (message, callback) => {
      console.log(message, callback);
      showCustomWizardConfirm(callback)

   }



   const showCustomWizardConfirm = (ev) => {
      setAlert(
         <SweetAlert
            warning
            showCancel
            confirmBtnText="Continue!"
            confirmBtnBsStyle="danger"
            title="Exit Wizard"
            onConfirm={() => {
               ev(true);
               setAlert(null);
            }}
            onCancel={() => setAlert(null)}
            focusCancelBtn
         >
            Do you want to exit from customer wizard section?
         </SweetAlert>
      )
   }

   return (
      <>
         <Router getUserConfirmation={getUserConfirmation}>

            <>
               {alert}
               <Loader />
               <ScrollIntoView>
                  <Switch>
                     <Route exact path="/" render={() => <UnAuthLayout><Login /></UnAuthLayout>} />
                     {allRoutes.map((item, key) => item.isPrivate ?
                        <PrivateRoute exact path={item.path} component={item.component} key={key} {...item} />
                        :
                        <PublicRoute exact path={item.path} component={item.component} key={key} {...item} />
                     )}
                     <Route exact path="*" render={() => <Redirect to="/login" />} />
                  </Switch>
               </ScrollIntoView>
            </>
         </Router>
      </>
   );
}

export default App;
