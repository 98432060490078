import React, { useState, useEffect } from 'react'
import { TextInput } from '../../form-elements/textInput'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateInput } from '../../form-elements/datepicker';
import { CheckboxInput } from '../../form-elements/checkboxInput';
import { SelectInput } from '../../form-elements/dropdown';
import { useMedicationApi } from '../../../hooks/api/medcationApiHook';
import { toast } from 'react-toastify';
import { RadioGroup } from '../../form-elements/radioGroup';
import moment from 'moment';
import { changeDate } from '../../form-elements/functions';

const DURATIONS = [
   { label: 'Days', value: 'day' },
   { label: 'Weeks', value: 'week' },
   { label: 'Months', value: 'month' }
]

const PHARMACY = [
   { label: 'Senior Wellness Solutions', value: 'Senior Wellness Solutions' },
   { label: 'Others', value: 'Others' }
]

const schema = yup.object().shape({
   name: yup.string().required('Name is required').max(200, 'Max 200 characters'),
   // strength: yup.string().required('Strength is required').max(200, 'Max 200 characters'),
   // units: yup.string().required('Units is required').max(200, 'Max 200 characters'),
   strength: yup.string(),
   // units: yup.string(),
   spDirections: yup.string().required('Directions is required').max(250, 'Max 250 characters'),
   physician: yup.string().required('Physician is required').max(250, 'Max 250 characters'),
   prescription: yup.string().required('Prescription is required').max(250, 'Max 250 characters'),
   refillis: yup.string().required('Refills is requried').matches(/^[0-9]+$/, 'Refills can have numbers only'),
   // pharmacy: yup.string().required('Name of pharmacy is required').max(250, 'Max 250 characters'),
   pharmacy: yup.object().shape({
      label: yup.string(),
      value: yup.string()
   }).nullable().required('Name of pharmacy is required'),
   otherpharmacy: yup.string().max(250, 'Max 250 characters'),
   Treatment: yup.boolean(),
   // rx_prn_qty: yup
   //    .string()
   //    .when(["Am", "Pm", "Evening", "Bed", "CmfKit", "Pnr", "Treatment"], {
   //       is: (Am, Pm, Evening, Bed, CmfKit, Pnr, Treatment) => Am || Pm || Evening || Bed || CmfKit || Pnr || Treatment, //true
   //       then: yup.string()
   //          .typeError('Quantity is requird')
   //          .required("Quantity is requird")
   //          // .positive("Quantity must be more than 0")
   //          // .integer("Quantity must be more than 0")
   //          .matches(/\d*[1-9][0-9]*\d*$/, 'Quantity can have numbers only and must be more than 0')
   //    }),
   prn_qty: yup
      .string()
      .when(["Am", "Pm", "Evening", "Bed", "CmfKit", "Pnr", "Treatment"], {
         is: (Am, Pm, Evening, Bed, CmfKit, Pnr, Treatment) => Am || Pm || Evening || Bed || CmfKit || Pnr || Treatment, //true
         then: yup.string()
            .typeError('Quantity is requird')
            .required("Quantity is requird")
            .matches(/^([1-9]\d*)(\.\d+)?$/, 'Quantity can have numbers only and must be more than 0')
            // .matches(/\d*[1-9][0-9]*\d*$/, 'Medication left can have numbers only and must be more than 0')
      }),
   // prn_gap: yup
   //    .string()
   //    .when("Pnr", {
   //       is: true,
   //       then: yup.string().required("Minimum hours is requird").matches(/^$|^[0-9]+$/, 'Minimum hours can have numbers only').min(1, 'Minimum 1 required')
   //    }),
   treatmentDuration: yup
      .string()
      .when("Treatment", {
         is: true,
         then: yup.string().required("Duration is requird").matches(/^[0-9]$/i, 'Invalid duration').min(1, 'Minimum 1 required')
      }),
   treatmentDurationType: yup.object().when('Treatment', {
      is: true,
      then: yup.object().shape({
         label: yup.string(),
         value: yup.string()
      }).nullable().required('Duration type is required')
   }),
   expirationDate: yup.string()
      .nullable()
      .test('Length', 'Invalid expiration date', function (value) {
         value = value && value.replace(/_/g, '')
         return value && value.length === 10 && moment(value, 'MM-DD-YYYY').isValid();
      }),
   dateFilled: yup.string()
      .nullable()
      .test('Length', 'Invalid date filled', function (value) {
         value = value && value.replace(/_/g, '')
         return value && value.length === 10 && moment(value, 'MM-DD-YYYY').isValid();
      }),
   dateStarted: yup.string()
      .nullable()
})

const MedicationForm = (props) => {
   const [freq, setFreq] = useState([])
   const [phrmacy, setPharmacy] = useState({})
   const [medDtls, setMedDtls] = useState({})

   const { handleSubmit, errors, control, reset, setValue, watch, getValues } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema)
   });

   useEffect(() => {
      console.log("add staff form add stuff form", props.data.id,props.data.fromThirdParty);
      if (props.data.id) {
         let temp = { ...props.data }
         setFreq(temp.freq)
         // const prn_qty = Number.isInteger(temp.prn_qty) ? temp.prn_qty.toString() : temp.prn_qty.toString().split(".")[0];
         // console.log(prn_qty);
         // temp.rx_prn_qty = temp.rx_prn_qty.toString();
         temp.prn_qty = temp.prn_qty;
         temp.pharmacy = temp.pharmacy ? (temp.pharmacy != 'Senior Wellness Solutions' ? PHARMACY.find(item => item.value === 'Others') : PHARMACY.find(item => item.value === temp.pharmacy)) : ''
         temp.otherpharmacy = props.data.pharmacy
         temp.dateFilled = temp.dateFilled ? changeDate(temp.dateFilled) : ''
         temp.expirationDate = temp.expirationDate ? changeDate(temp.expirationDate) : ''
         temp.dateStarted = temp.dateStarted ? changeDate(temp.dateStarted) : ''
         temp.treatmentDuration = temp.treatmentDuration || ''
         temp.treatmentDurationType = DURATIONS.find(item => item.value === temp.treatmentDurationType)
         if (temp?.freq?.length) {
            temp?.freq.map(item => { temp[item.time] = true })
         }
         setMedDtls(temp);
         reset(temp)
         console.log(temp)

      }


      var utctime = "Thu, 23 Jun 2022 00:00:00 GMT"

      const date = new Date('2022-06-05 05:30:00');

      // console.log("datedatedatedatedate", date);

      const pst = date.toLocaleString('en-US', {
         timeZone: 'America/Los_Angeles',
      });

      // console.log("pstpstpstpst", pst);

   }, [props.data])

   const pharma = watch('pharmacy')
   const needed = watch('Pnr')
   const cKit = watch('CmfKit')
   const isTreatment = watch('Treatment')
   const am = watch('Am')
   const pm = watch('Pm')
   const evening = watch('Evening')
   const bedTime = watch('Bed')


   const medicationApi = useMedicationApi()

   // useEffect(() => {
   //    if(!am || !pm || !evening || !bedTime || !needed || !isTreatment){
   //       setFreq(true)
   //    }else{
   //       setFreq(false)
   //    }
   // },[])

   useEffect(() => {
      if (pharma) {
         let temp = { ...getValues() }
         temp.pharmacy = pharma
         // setPharmacy(temp.pharmacy)
         console.log(pharma)
      }

   }, [pharma])


   useEffect(() => {
      if (needed === true) {
         let temp = { ...getValues() }
         temp.Am = false
         temp.Pm = false
         temp.Evening = false
         temp.Bed = false
         temp.Treatment = false
         temp.CmfKit = false
         if (props.data.id) {
            if (temp.refillis) {
               temp.refillis = temp.refillis
            }
            else {
               temp.refillis = '0'
            }
         }
         reset(temp)
      }
   }, [needed])

   useEffect(() => {
      if (isTreatment === true) {
         let temp = { ...getValues() }
         temp.Am = false
         temp.Pm = false
         temp.Evening = false
         temp.Bed = false
         temp.CmfKit = false
         temp.Pnr = false
         if (props.data.id) {
            if (temp.refillis) {
               temp.refillis = temp.refillis
            }
            else {
               temp.refillis = '0'
            }
         }
         reset(temp)
      }
   }, [isTreatment])

   useEffect(() => {
      if (am || pm || evening || bedTime) {
         let temp = { ...getValues() }
         console.log('temp value', temp)
         temp.CmfKit = false
         temp.Pnr = false
         temp.Treatment = false
         if (props.data.id) {
            if (temp.refillis) {
               temp.refillis = temp.refillis
            }
            else {
               temp.refillis = '0'
            }
         }
         reset(temp)
      }
   }, [am, pm, evening, bedTime])

   useEffect(() => {
      if (cKit === true) {
         let temp = { ...getValues() }
         temp.Am = false
         temp.Pm = false
         temp.Evening = false
         temp.Bed = false
         temp.Treatment = false
         temp.Pnr = false
         if (props.data.id) {
            if (temp.refillis) {
               temp.refillis = temp.refillis
            }
            else {
               temp.refillis = '0'
            }
         }
         reset(temp)
      }
   }, [cKit])


   const onSubmit = (data) => {
      console.log("calling from submit")
      console.log(data, pharma)
      let temp = { ...data }
      Object.keys(temp).map(key => {
         if (temp[key] === '' || temp[key] === false) {
            delete temp[key]
         }
      })

      //delete temp.dateFilled; delete temp.dateStarted; delete temp.expirationDate;
      if (data.pharmacy.value == 'Senior Wellness Solutions') {
         temp.pharmacy = data.pharmacy.value
      } else {
         if (data.pharmacy.value == 'Others' && data.otherpharmacy) {
            temp.pharmacy = data.otherpharmacy
         }
         else {
            toast.error("Please enter other pharmacy name")
         }

      }
      delete temp.otherpharmacy;

      if (data.prn_gap) { temp.prn_gap = parseInt(data.prn_gap) }
      if (data.prn_qty) { temp.prn_qty = parseFloat(data.prn_qty) } //parseInt(data.prn_qty) }
      // if (data.rx_prn_qty) { temp.rx_prn_qty = parseInt(data.rx_prn_qty) }
      if (data.refillis) { temp.refillis = parseInt(data.refillis) }
      else {
         temp.refillis = parseInt('0')
      }
      if (data.dateFilled) { temp.dateFilled = moment(data.dateFilled, 'MM-DD-YYYY').format('YYYY-MM-DD') }
      if (data.dateStarted) { temp.dateStarted = moment(data.dateStarted, 'MM-DD-YYYY').format('YYYY-MM-DD') }
      if (data.expirationDate) { temp.expirationDate = moment(data.expirationDate, 'MM-DD-YYYY').format('YYYY-MM-DD') }
      if (isTreatment) {
         temp.treatmentDuration = parseInt(data.treatmentDuration)
         temp.treatmentDurationType = data.treatmentDurationType.value
      }

      let finalFreq = []

      if (data.Treatment) {
         let tempObj = props.data.id ? freq.find(item => item.time === 'Treatment') : ''
         finalFreq.push({ time: 'Treatment', id: tempObj ? tempObj.id : '' })
      }
      if (data.CmfKit) {
         let tempObj = props.data.id ? freq.find(item => item.time === 'CmfKit') : ''
         finalFreq.push({ time: 'CmfKit', id: tempObj ? tempObj.id : '' })
      }
      if (data.Pnr) {
         let tempObj = props.data.id ? freq.find(item => item.time === 'Pnr') : ''
         finalFreq.push({ time: 'Pnr', id: tempObj ? tempObj.id : '' })
      }
      else {
         if (data.Am) {
            let temp = props.data.id ? freq?.find(item => item.time === 'Am') : ''
            finalFreq.push({ time: 'Am', id: temp ? temp.id : '' })
         }
         if (data.Pm) {
            let temp = props.data.id ? freq?.find(item => item.time === 'Pm') : ''
            finalFreq.push({ time: 'Pm', id: temp ? temp.id : '' })
         }
         if (data.Evening) {
            let temp = props.data.id ? freq?.find(item => item.time === 'Evening') : ''
            finalFreq.push({ time: 'Evening', id: temp ? temp.id : '' })
         }
         if (data.Bed) {
            let temp = props.data.id ? freq?.find(item => item.time === 'Bed') : ''
            finalFreq.push({ time: 'Bed', id: temp ? temp.id : '' })
         }

      }
      temp['frequency'] = finalFreq

      temp['residentId'] = props.resId
      console.log(temp);
      if (props.data.id) {
         temp['id'] = props.data.id
         // if(temp?.refillis <= 0 || temp?.refillis <= '0'){
         //    toast.error('The refills should be at least 1.')
         // return true
         //    }
         medicationApi.updateMedication(temp,
            (message, resp) => {
               toast.success('Medication updated successfully')
               props.submitMedicine()
            }, (message) => {
               toast.error(message)
            })
      }
      else {
         // if(temp?.refillis <= 0 || temp?.refillis <= '0'){
         //    toast.error('The refills should be at least 1.')
         // return true
         //    }
         temp.rx_prn_qty = parseFloat(data.prn_qty)
         medicationApi.addMedication(temp,
            (message, resp) => {
               toast.success('Medication added successfully')
               props.submitMedicine()
            }, (message) => {
               toast.error(message[0])
            })
      }
   }
   const onchange = (event) => {
      console.log('event.target.value', event.target.value)
   }

   return (
      <div className="row">
         <div className="col-lg-12">
            <form onSubmit={handleSubmit(onSubmit)}>
               <div className="row">
                  <div className="col-lg-6" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="name"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.name}
                                 label='Name'
                                 required
                                 maxLength={200}
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>

               <label className="form-label">Frequency</label>
               <div className="row">
                  <div className="col-lg-6">
                     <Controller
                        defaultValue={false}
                        control={control}
                        name="Pnr"
                        render={({ ref, value, ...props }) => (
                           <CheckboxInput
                              inputref={ref}
                              value={value}
                              label='As Needed (PRN)'
                              {...props}
                           />
                        )}
                     />
                  </div>
                  {/* <div className="col-lg-4">
                     <Controller
                        defaultValue={false}
                        control={control}
                        name="Treatment"
                        render={({ ref, value, ...props }) => (
                           <CheckboxInput
                              inputref={ref}
                              value={value}
                              label='Treatment'
                              {...props}
                           />
                        )}
                     />
                  </div> */}
               </div>

               {!needed &&
                  <div className="row">
                     <div className="col-6 col-md-3" data-select2-id={1}>
                        <Controller
                           defaultValue={false}
                           control={control}
                           name="Am"
                           render={({ ref, value, ...props }) => (
                              <CheckboxInput
                                 inputref={ref}
                                 value={value}
                                 label='Morning'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                     <div className="col-6 col-md-3">
                        <Controller
                           defaultValue={false}
                           control={control}
                           name="Pm"
                           render={({ ref, value, ...props }) => (
                              <CheckboxInput
                                 inputref={ref}
                                 value={value}
                                 label='Afternoon'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                     <div className="col-6 col-md-3">
                        <Controller
                           defaultValue={false}
                           control={control}
                           name="Evening"
                           render={({ ref, value, ...props }) => (
                              <CheckboxInput
                                 inputref={ref}
                                 value={value}
                                 label='Evening'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                     <div className="col-6 col-md-3">
                        <Controller
                           defaultValue={false}
                           control={control}
                           name="Bed"
                           render={({ ref, value, ...props }) => (
                              <CheckboxInput
                                 inputref={ref}
                                 value={value}
                                 label='Bed Time'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
               }

               {/* <label className="form-label">Comfort Kit</label> */}
               <div className="row">
                  <div className="col-lg-4" data-select2-id={1}>
                     <Controller
                        defaultValue={false}
                        control={control}
                        name="CmfKit"
                        render={({ ref, value, ...props }) => (
                           <CheckboxInput
                              inputref={ref}
                              value={value}
                              label='Comfort Kit'
                              error={errors.CmfKit ? 'kkk' : ''}
                              {...props}
                           />
                        )}
                     />
                  </div>
               </div>

               {errors?.Am && <div className="text-danger">
                  <small> Frequency is required </small>
               </div>}
               {/* {(needed || isTreatment) &&  */}
               
               {/* {(am || pm || evening || bedTime || needed || isTreatment || cKit) &&  */}
               <div className="row mt-2">
                  {/* {needed ? "col-lg-4" : "col-lg-6"} */} 
                  <div className="col-lg-6">
                     <div className="form-group">
                        <Controller
                           defaultValue='1'
                           control={control}
                           name="prn_qty"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.prn_qty}
                                 label='Quantity'
                                 readOnly={medDtls.fromThirdParty === 1}
                                 required
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  
                  {/* {(am || pm || evening || bedTime || cKit) &&  */}
                  <div className="col-lg-6">
                     <div className="form-group">
                        <Controller
                           defaultValue=''
                           control={control}
                           name="strength"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.strength}
                                 label='Strength'
                                 maxLength={200}
                                 type='text'
                                 // required
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  {/* } */}
                  
                  {isTreatment && <div className="col-lg-4">
                     <div className="form-group">
                        <Controller
                           defaultValue=''
                           control={control}
                           name="treatmentDuration"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.treatmentDuration}
                                 label='Duration'
                                 required
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>}
                  {isTreatment && <div className="col-lg-4">
                     <div className="form-group">
                        <label className="form-label">Duration Type</label>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="treatmentDurationType"
                           render={({ ref, value, ...rest }) => (
                              <SelectInput
                                 error={errors.treatmentDurationType}
                                 inputref={ref}
                                 value={value}
                                 options={DURATIONS}
                                 {...rest}
                              />
                           )}
                        />
                     </div>
                  </div>}

                  {/* {needed && <div className={needed ? "col-lg-4" : "col-lg-6"}>
                     <div className="form-group">
                        <Controller
                           defaultValue=''
                           control={control}
                           name="prn_gap"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.prn_gap}
                                 label='Minimum hours between doses'
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>}
                  {needed && <div className={needed ? "col-lg-4" : "col-lg-6" + ((isTreatment || needed) ? "" : ' mt-2')}>
                  <div className="form-group">
                     <Controller
                        defaultValue=''
                        control={control}
                        name="strength"
                        render={({ ref, value, ...props }) => (
                           <TextInput
                              inputref={ref}
                              value={value}
                              error={errors.strength}
                              label='Strength'
                              maxLength={200}
                              type='text'
                              required
                              {...props}
                           />
                        )}
                     />
                  </div>
                  </div>} */}
               </div>
               {/* } */}
               {/* } */}

               {/* {(isTreatment || needed) && <div className="row">
                  <div className={needed ? "col-lg-4" : "col-lg-6" + ((isTreatment || needed) ? "" : ' mt-2')}>
                     <div className="form-group">
                        <Controller
                           defaultValue=''
                           control={control}
                           name="strength"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.strength}
                                 label='Strength'
                                 maxLength={200}
                                 type='text'
                                 required
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div> */}
                  {/* <div className={"col-lg-6" + ((isTreatment || needed) ? "" : ' mt-2')}>
                     <div className="form-group">
                        <Controller
                           defaultValue=''
                           control={control}
                           name="units"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.units}
                                 maxLength={200}
                                 label='Units'
                                 // required
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div> */}
               {/* </div>} */}

               <div className={"row" + ((am || pm || evening || bedTime || cKit || isTreatment) ? '' : ' mt-2')}>
                  <div className="col-lg-12" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="spDirections"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.spDirections}
                                 label='Directions'
                                 type='textarea'
                                 maxLength={250}
                                 required
                                 {...props}
                              />
                           )}
                        />
                        <small className="text-muted float-right">(Maximum 250 characters)</small>
                     </div>
                  </div>
               </div>

               <div className="row mt-3">
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Expiration Date <span className="text-danger">*</span></label>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="expirationDate"
                           render={({ ref, value, ...props }) => (
                              <DateInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.expirationDate}
                                 placeholder='mm-dd-yyyy'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Date Filled <span className="text-danger">*</span></label>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="dateFilled"
                           render={({ ref, value, ...props }) => (
                              <DateInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.dateFilled}
                                 placeholder='mm-dd-yyyy'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Date Started </label>
                        {/* <span className="text-danger">*</span> */}
                        <Controller
                           defaultValue=''
                           control={control}
                           name="dateStarted"
                           render={({ ref, value, ...props }) => (
                              <DateInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.dateStarted}
                                 placeholder='mm-dd-yyyy'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>

               <div className="row">
                  <div className="col-lg-6">
                     <div className="form-group">
                        <Controller
                           defaultValue=''
                           control={control}
                           name="physician"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.physician}
                                 label='Prescribing Physician'
                                 type='text'
                                 required
                                 maxLength={250}
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-6">
                     <div className="form-group">
                        <Controller
                           defaultValue=''
                           control={control}
                           name="prescription"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.prescription}
                                 label='Prescription No.'
                                 type='text'
                                 required
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>

               <div className="row">
                  <div className="col-lg-6">
                     <div className="form-group">
                        <Controller
                           defaultValue=''
                           control={control}
                           name="refillis"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.refillis}
                                 label='No. of refills'
                                 type='text'
                                 required
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>

                  <div className="col-lg-6">
                     <div className="form-group">
                        <label className="form-label">Name of pharmacy</label><span className="text-danger"> *</span>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="pharmacy"
                           render={({ ref, value, ...rest }) => (
                              <SelectInput
                                 error={errors.pharmacy}
                                 inputref={ref}
                                 value={value}
                                 required
                                 options={PHARMACY}
                                 {...rest}
                              />
                           )}
                        />
                     </div>
                  </div>

                  {/* <div className="col-lg-6" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Name of pharmacy<span className="text-danger"> *</span></label>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="pharmacy"
                           render={({ ref, value, ...rest }) => (
                              <RadioGroup
                                 name="pharmacy"
                                 value={value == 'Senior Wellness Solutions' ? 'Senior Wellness Solutions' : 'Others'}
                                 inputref={ref}
                                 required
                                 options={[
                                    { label: 'Senior Wellness Solutions', value: 'Senior Wellness Solutions' },
                                    { label: 'Others', value: 'Others' },
                                 ]}
                                 error={errors.pharmacy}
                                 {...rest}
                              />
                           )}
                        />
                     </div>
                  </div> */}

                  <div className="col-lg-6"></div>
                  {pharma?.value == 'Others' && <div className="col-lg-6 phrmcy-mt-1">
                     <div className="form-group">
                        <Controller
                           defaultValue=''
                           control={control}
                           name="otherpharmacy"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 placeholder="Other Pharmacy Name"
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>}
               </div>

               <p className="text-center mt-2 font-weight-bold"><span className="text-danger">*</span> Required fields to auto complete your Lic. 602</p>

               <div className="d-flex justify-content-end mt-2">
                  <button className="btn btn-primary waves-light" type="submit">Submit</button>
               </div>
            </form>
         </div>
      </div>
   )
}

export default MedicationForm