import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { TextInput } from '../../form-elements/textInput';
import { useHistory, useLocation } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useCommonApi } from '../../../hooks/api/commonApiHook'
import { useResidentApi } from '../../../hooks/api/residentApiHook';
import moment from 'moment';
import RoleCheckHOC from '../../../hoc/roleCheckHoc';
import { useFacilityApi } from '../../../hooks/api/facilityApiHook';
import { toast } from 'react-toastify';
import { ACTIONS, APIS, MODULES, OPERATIONS, } from '../../../config/config';
import axios from 'axios'
import { useDispatch } from 'react-redux';
import { RadioGroup } from '../../form-elements/radioGroup';
import ImageModal from '../../form-elements/imageModal';
import { changeDate, encryptId } from '../../form-elements/functions';
import { useUserSelector } from '../../../hooks/selectors/userSelector';

const schema = yup.object().shape({
   firstName: yup.string().required('First name is required').max(20, 'Max 20 characters'),
   lastName: yup.string().required('Last name is required').max(40, 'Max 40 characters'),
   email: yup.string().required('Email is required').email('Invalid email'),

})

const ResidentFormFamilyMember = forwardRef((props, ref) => {
   const refe = React.useRef()
   const [isDisplay, setDisplay] = useState(true)

   const { handleSubmit, errors, control, remove, reset, setValue, watch, getValues, trigger, 
      setError, clearErrors, onBlur } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema)
   });

   const commonApi = useCommonApi()
   const residentApi = useResidentApi()
   const facilityApi = useFacilityApi()
   const user = useUserSelector()
   const dispatch = useDispatch()
   const history = useHistory()
   const location = useLocation()
   const id = props.userId ? props.userId : '';

   const isResiding = watch('residing')

   useEffect(() => {
      // console.log("propspropspropspropspropsprops", props);
      if (location.hash) {
         let temp = location.hash.replace('#', '')
         let ele = document.getElementById(temp)
         window.scrollTo(ele.offsetLeft, ele.offsetTop);
      }
   }, [])

   const onSubmit = (data) => {
      console.log("data",data);
      var data = getValues();

      // data.isActive = data.isActive == '1' ? true : false
      // data.birthDate = data.birthDate ? moment(data.birthDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      // data.movedInDate = data.movedInDate ? moment(data.movedInDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      // data.movedOut = data.movedOut ? moment(data.movedOut, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      // data.depositAccepted = data.depositAccepted ? moment(data.depositAccepted, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      // data.deathDate = data.deathDate ? moment(data.deathDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      // data.gender = data.gender.value
      // data.isVaccinated = data.isVaccinated ? data.isVaccinated : ""
      // data.vaccineComment = data.vaccineComment ? data.vaccineComment : ""
      // data.facilityId = parseInt(data?.facility?.value)

      // if (data.phone) {
      //    data.phone = data.phone.replace(/[^\d]/g, '')
      // }
      // else {
      //    data.phone = ""
      // }

      // if (photo && photo.id) {
      //    data.photoId = photo.id
      // }
      //data.dnrPolstId = dnr.id ? dnr.id : null
      // if (!isResiding) {
      //    data.residing = null
      //    data.hospice = ''
      //    data.hospiceContName = ''
      //    data.hospicePhoneNo = ''
      // }
      if (!data.email) {
         delete data.email
      }


      if ((data.firstName == '' || data.firstName == undefined) || (data.lastName == '' || data.lastName == undefined)) {
         trigger();

      }
      else {
         console.log("data", data);
         residentApi.addResidentFamilyMember(data,
            (message, resp) => {
               toast.success('Resident added successfully')
               
               history.push('/resident-list')
            }, (message) => {
               toast.error(message)
            })
      }

   }



   useEffect(() => {
      if (isResiding === '') {
         setValue('isResiding', '')
      }
   }, [isResiding])



   const onError = (errors, e) => {
      // console.log("errorserrorserrorserrorserrors", errors);
      let errorsArray = Object.keys(errors)
      if (errorsArray.length) {
         window.scrollTo({ top: 0, behavior: 'smooth' });
      }
   }

   useImperativeHandle(ref, () => ({

      getAlert() {
         return getValues()
      }

   }));



   return (
      <>
         {/* {alert} */}
         <div style={{ position: 'relative', top: '4rem', minHeight: '400px' }}>
            {isDisplay && (
               <form onSubmit={handleSubmit(onSubmit, onError)} className='mt-5'>
                  <div className="p-3">
                     <h5 className="mb-4">Add Resident</h5>
                     <div className="row">
                        <div className="col-lg-4" data-select2-id={1}>
                           <div className="form-group" data-select2-id={15}>
                              <Controller
                                 defaultValue=''
                                 control={control}
                                 name="firstName"
                                 render={({ ref, value, ...props }) => (
                                    <TextInput
                                       inputref={ref}
                                       value={value}
                                       error={errors.firstName}
                                       label='First Name'
                                       placeholder='First name'
                                       required
                                       type='text'
                                       maxLength={20}
                                       {...props}
                                    />
                                 )}
                              />
                           </div>
                        </div>
                        <div className="col-lg-4" data-select2-id={1}>
                           <div className="form-group" data-select2-id={15}>
                              <Controller
                                 defaultValue=''
                                 control={control}
                                 name="lastName"
                                 render={({ ref, value, ...props }) => (
                                    <TextInput
                                       inputref={ref}
                                       value={value}
                                       error={errors.lastName}
                                       label='Last Name'
                                       placeholder='Last name'
                                       required
                                       type='text'
                                       maxLength={40}
                                       {...props}
                                    />
                                 )}
                              />
                           </div>
                        </div>
                        <div className="col-lg-4" data-select2-id={1}>
                           <div className="form-group" data-select2-id={15}>
                              <Controller
                                 defaultValue=''
                                 control={control}
                                 name="email"
                                 render={({ ref, value, ...rest }) => (
                                    <TextInput
                                       inputref={ref}
                                       type='text'
                                       value={value}
                                       error={errors.email}
                                       label='Email'
                                       placeholder='Email'
                                       required
                                       {...rest}
                                    />
                                 )}
                              />
                           </div>
                        </div>
                     </div>

                     {/* Optional HR separator */}
                     {/* <hr style={{ height: '4px', backgroundColor: '#e8e8e8' }} /> */}

                     {/* Submit Button at the bottom-right */}
                     <div className="d-flex justify-content-end mb-4">
                        <button type="submit" className="btn btn-primary mr-2 mt-0">
                           Save
                        </button>
                     </div>
                  </div>
               </form>
            )}
         </div>

      </>
   )
}
)

export default ResidentFormFamilyMember