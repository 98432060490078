import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { TextInput } from '../../form-elements/textInput';
import { useHistory, useLocation } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { DateInput } from '../../form-elements/datepicker';
import { SelectInput } from '../../form-elements/dropdown';
import { MaskInput } from '../../form-elements/maskInput';
import { EditorInput } from '../../form-elements/editor';
import { useCommonApi } from '../../../hooks/api/commonApiHook'
import { useResidentApi } from '../../../hooks/api/residentApiHook';
import moment from 'moment';
import RoleCheckHOC from '../../../hoc/roleCheckHoc';
import { useFacilityApi } from '../../../hooks/api/facilityApiHook';
import { toast } from 'react-toastify';
import { ACTIONS, APIS, MODULES, OPERATIONS, } from '../../../config/config';
import axios from 'axios'
import { useDispatch } from 'react-redux';
import { RadioGroup } from '../../form-elements/radioGroup';
import ImageModal from '../../form-elements/imageModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import { changeDate, encryptId } from '../../form-elements/functions';
import LazyImage from '../../form-elements/lazyImage';
import { useUserSelector } from '../../../hooks/selectors/userSelector';

const genderOptions = [
   { label: 'Male', value: 'male' },
   { label: 'Female', value: 'female' },
   { label: 'Other', value: 'other' }
]

const schema = yup.object().shape({
   // .matches(/^[a-zA-Z, ]+$/, 'Invalid first name')
   // .matches(/^[a-zA-Z. _-]+$/, 'Invalid last name')
   firstName: yup.string().required('First name is required').max(20, 'Max 20 characters'),
   lastName: yup.string().required('Last name is required').max(40, 'Max 40 characters'),
   nickname: yup.string().nullable().max(40, 'Max 40 characters'),
   gender: yup.object().shape({
      label: yup.string(),
      value: yup.string()
   }).nullable().required('Gender is required'),
   facility: yup.object().shape({
      label: yup.string(),
      value: yup.string()
   }).nullable().required('Facility is required'),
   // language: yup.string().nullable().matches(/^$|^[a-zA-Z ]+$/i, 'Language can contain letters only').max(40, 'Max 40 characters'),
   language: yup.string().nullable().max(40, 'Max 40 characters'),

   religion: yup.string().nullable().matches(/^$|^[a-zA-Z ]+$/i, 'Religion can contain letters only').max(40, 'Max 40 characters'),
   race: yup.string().nullable().matches(/^$|^[a-zA-Z ]+$/i, 'Race can contain letters only').max(40, 'Max 40 characters'),
   phone: yup.string().nullable().transform(val => val && val.replace(/[^\d]/g, '')).test('len', 'Invalid phone number', val => val ? val.length === 10 : true),
   email: yup.string().nullable().email('Invalid email'),
   hospice: yup.string().when('residing', {
      is: residing => residing.length > 0,
      then: yup.string().required('Name is required')
   }),
   hospiceContName: yup.string().when('residing', {
      is: residing => residing.length > 0,
      then: yup.string().required('Contact name is required').matches(/^[a-zA-Z ]+$/i, 'Only letters allowed')
   }),
   hospicePhoneNo: yup.string().when('residing', {
      is: residing => residing.length > 0,
      then: yup.string().transform(val => val && val.replace(/[^\d]/g, '')).test('len', 'Invalid phone number', val => val.length === 10)
   }),
   ssn: yup.string().nullable().transform(val => val && val.replace(/[^\d]/g, '')).test('len', 'Invalid SSN', val => val ? val.length === 9 : true),
   movedInDate: yup.string()
      .nullable()
      .test('Length', 'Invalid moved in date', function (value) {
         value = value && value.replace(/_/g, '')
         return value && value.length === 10 && moment(value, 'MM-DD-YYYY').isValid();
      }),
   birthDate: yup.string()
      .nullable()
      .test('Length', 'Invalid date of birth', function (value) {
         value = value && value.replace(/_/g, '')
         return value && value.length === 10 && moment(value, 'MM-DD-YYYY').isValid() && moment(value, 'MM-DD-YYYY').isSameOrBefore();
      }),
   depositAccepted: yup.string()
      .nullable()
      .test('Length', 'Invalid deposit accepted date', function (value) {
         value = value && value.replace(/_/g, '')
         return value && value.length ? value.length === 10 && moment(value, 'MM-DD-YYYY').isValid() : true;
      }),
   deathDate: yup.string()
      .nullable()
      .test('Length', 'Invalid death date', function (value) {
         value = value && value.replace(/_/g, '')
         return value && value.length ? value.length === 10 && moment(value, 'MM-DD-YYYY').isValid() && moment(value, 'MM-DD-YYYY').isSameOrBefore() : true;
      }),
   movedOut: yup.string()
      .nullable()
      .test('Length', 'Invalid moved out date', function (value) {
         value = value && value.replace(/_/g, '')
         return value && value.length ? value.length === 10 && moment(value, 'MM-DD-YYYY').isValid() : true;
      }),
   monthlyRate: yup.string().nullable()
      .matches(/^([0-9]\d*)(\.\d+)?$/, 'Max Resident no. can have numbers only'),
   roomNo: yup.string().nullable()
})

//const ResidentForm = (props) => {
const ResidentForm = forwardRef((props, ref) => {
   const refe = React.useRef()
   const [photo, setPhoto] = useState({})
   const [files, setFiles] = useState([])
   const [docs, setDocs] = useState([])
   const [facilities, setFacilities] = useState([])
   const [isOpen, setOpen] = useState(false)
   const [alert, setAlert] = useState(null)
   const [isWizard, setWizard] = useState(props.isWizard)
   const [isPhotoRemoved, setPhotoRemoved] = useState(false)
   const [isDisplay, setDisplay] = useState(true)
   // const [isVaccinated, setVaccinated] = useState('yes')
   const [getData, setData] = useState({})
   const [userFacility, setUserFacility] = useState([])

   const { handleSubmit, errors, control, remove, reset, setValue, watch, getValues, trigger, setError, 
      clearErrors, onBlur, formState: { isSubmitting }, } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema)
   });

   const commonApi = useCommonApi()
   const residentApi = useResidentApi()
   const facilityApi = useFacilityApi()
   const user = useUserSelector()
   const dispatch = useDispatch()
   const history = useHistory()
   const location = useLocation()
   const id = props.userId ? props.userId : '';

   const isResiding = watch('residing')
   const isFacility = watch('facility')
   const isVaccinated = watch('isVaccinated');
   const medicalFileRef = React.useRef(null);
   const carePlanRef = React.useRef(null);

   useEffect(() => {
      // console.log("propspropspropspropspropsprops", props);
      if (location.hash) {
         let temp = location.hash.replace('#', '')
         let ele = document.getElementById(temp)
         window.scrollTo(ele.offsetLeft, ele.offsetTop);
      }
      getFacilities()
   }, [])



   const skiptonextstep = () => {
      history.push('/dashboard');

   }

   const saveAndClose = () => {
      var data = getValues();

      // console.log("save and close get data value", data, data.facility);
      // if(data.facility.label == undefined){
      //    data.facility = null;
      // }
      data['documents'] = files.length ? files.filter(item => item.isNew === true) : []
      data.isActive = data.isActive == '1' ? true : false
      data.birthDate = data.birthDate ? moment(data.birthDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      data.movedInDate = data.movedInDate ? moment(data.movedInDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      data.movedOut = data.movedOut ? moment(data.movedOut, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      data.depositAccepted = data.depositAccepted ? moment(data.depositAccepted, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      data.deathDate = data.deathDate ? moment(data.deathDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      data.gender = data.gender.value
      data.isVaccinated = data.isVaccinated ? data.isVaccinated : ""
      data.vaccineComment = data.vaccineComment ? data.vaccineComment : ""
      data.facilityId = parseInt(data?.facility?.value)

      if (data.phone) {
         data.phone = data.phone.replace(/[^\d]/g, '')
      }
      else {
         data.phone = ""
      }

      if (photo && photo.id) {
         data.photoId = photo.id
      }
      //data.dnrPolstId = dnr.id ? dnr.id : null
      if (!isResiding) {
         data.residing = null
         data.hospice = ''
         data.hospiceContName = ''
         data.hospicePhoneNo = ''
      }
      if (!data.email) {
         delete data.email
      }
      if (data.facility?.residentCount >= data.facility?.maxResident) {
         return;
      } else {
         // console.log("else")
         if (props.userId) {
            data['id'] = props.userId
            data['removePhoto'] = isPhotoRemoved;

            if (isWizard === true) {
               props.setforceExit(false);
            }
            // console.log(data.facility)
            if ((data.facility == undefined) || data.facility == '') {
               trigger();

            } else {
               residentApi.updateResident(data,
                  (message, resp) => {
                     // console.log("update response", resp);
                     toast.success('Resident updated successfully')
                     setTimeout(() => {
                        history.push('/resident-list')
                     }, 500);
                  }, (message) => {
                     toast.error(message.length ? message[0] : message)
                  })
            }
         }
         else {
            if ((data.firstName == '' || data.firstName == undefined) || (data.lastName == '' || data.lastName == undefined) || (data.gender == '' || data.gender == undefined) || (data.facility == '' || data.facility == undefined) || (data.movedInDate == '' || data.movedInDate == undefined)) {
               trigger();

            }
            else {
               residentApi.addResident(data,
                  (message, resp) => {
                     toast.success('Personal details added successfully')
                     // console.log("resprespresprespresp", resp);

                     reset()
                     reset({ movedInDate: "mm-dd-yyyy" })
                     reset({ birthDate: "mm-dd-yyyy" })

                     if (isWizard === true) {
                        props.setforceExit(false);
                     }
                     history.push('/resident-list')
                  }, (message) => {
                     toast.error(message)
                  })
            }
         }
      }
   }

   const getAnotherResident = () => {

      var data = getValues();
      // console.log(data)
      data['documents'] = files.length ? files.filter(item => item.isNew === true) : [];
      data.isActive = data.isActive == '1' ? true : false;
      data.birthDate = data.birthDate ? moment(data.birthDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : '';
      data.movedInDate = data.movedInDate ? moment(data.movedInDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : '';
      data.movedOut = data.movedOut ? moment(data.movedOut, 'MM-DD-YYYY').format('YYYY-MM-DD') : '';
      data.depositAccepted = data.depositAccepted ? moment(data.depositAccepted, 'MM-DD-YYYY').format('YYYY-MM-DD') : '';
      data.deathDate = data.deathDate ? moment(data.deathDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : '';
      data.gender = data.gender.value == '' ? '' : data.gender.value;
      data.isVaccinated = data.isVaccinated === 'yes' ? 'yes' : (data?.isVaccinated === 'unknown' ? 'unknown' : (data?.isVaccinated === 'no' ? 'No' : null));
      data.vaccineComment = data.vaccineComment;
      data.facilityId = parseInt(data.facility.value);
      if (data.phone) {
         data.phone = data.phone.replace(/[^\d]/g, '')
      }
      else {
         data.phone = ""
      }

      if (photo && photo.id) {
         data.photoId = photo.id
      }

      if (!isResiding) {
         data.residing = null
         data.hospice = ''
         data.hospiceContName = ''
         data.hospicePhoneNo = ''
      }
      if (!data.email) {
         delete data.email
      }

      if (data.facility?.residentCount >= data.facility?.maxResident) {
         return;
      } else {
      if (props.userId) {
         data['id'] = props.userId
         data['removePhoto'] = isPhotoRemoved;


         residentApi.updateResident(data,
            (message, resp) => {
               //toast.success('Resident updated successfully')
               setTimeout(() => {
                  reset();
                  if (isWizard === true) {
                     props.setforceExit(false);
                     history.push('/customer-wizard')
                  }

               }, 500);
            }, (message) => {
               toast.error(message)
            })
         // }
      }
      else {

         if ((data.firstName == '' || data.firstName == undefined) || (data.lastName == '' || data.lastName == undefined) || (data.gender == '' || data.gender == undefined) || (data.facility == '' || data.facility == undefined) || (data.movedInDate == '' || data.movedInDate == undefined)) {
            trigger();

         }
         else {
            if ((data.firstName == '' || data.firstName == undefined) || (data.lastName == '' || data.lastName == undefined) || (data.gender == '' || data.gender == undefined) || (data.facility == '' || data.facility == undefined) || (data.movedInDate == '' || data.movedInDate == undefined)) {
               trigger();

            }else{

            residentApi.addResident(data,
               (message, resp) => {
                  toast.success('Personal details added successfully')

                  while (files.length > 0) {
                     files.pop();
                  }
                  if (Object.keys(photo).length > 0) {
                     var photoObj = {
                        createdAt: "",
                        fileType: "",
                        fullUrl: "",
                        id: "",
                        mime: "",
                        name: "",
                        originalName: "",
                        path: ""
                     }
                     setPhoto(photoObj)
                  }
                  setTimeout(() => {
                     dispatch({ type: ACTIONS.LOADER.HIDE_LOADER })
                     setDisplay(true)

                  }, 3000);
                  setDisplay(false)
                  dispatch({ type: ACTIONS.LOADER.SHOW_LOADER })

                  //history.push('/resident-list')
               }, (message) => {
                  toast.error(message)
               })
            }
         }

      }
   }
   }

   const setUser = () => {
      // console.log(id)
      residentApi.residentDetails({ id },
         (message, resp) => {
            let data = { ...resp, ...resp?.medical }

            data.isVaccinated = data?.isVaccinated ? data?.isVaccinated : (data?.isVaccinated == 'no' ? 'No' : null);
            data.vaccineComment = data?.vaccineComment ? data?.vaccineComment : '';
            dispatch({ type: ACTIONS.RESIDENT.SET_NAME, payload: `${resp.firstName} ${resp.lastName}` })
            data.birthDate = changeDate(resp.birthDate)
            data.movedInDate = changeDate(resp.movedInDate)
            if (resp.facility == undefined) {
               data.facility = null;
            } else {
               data.facility = {
                  label: resp.facility && resp.facility.name,
                  value: resp.facility && resp.facility.id
               }
            }
            data.residing = data.residing ? data.residing : ''
            data.isActive = data.isActive ? '1' : '0'
            data.carePlan = data.carePlan ? data.carePlan : ''
            data.medicalCond = data.medicalCond ? data.medicalCond : ''
            data.gender = genderOptions.find(item => item.value === resp.gender)
            if (data.depositAccepted) {
               data.depositAccepted = changeDate(resp.depositAccepted)
            }
            if (data.deathDate) {
               data.deathDate = changeDate(resp.deathDate)
            }
            if (data.movedOut) {
               data.movedOut = changeDate(resp.movedOut)
            }
            reset(data)
            setPhoto(resp.photo)
            if (data.document.length) {
               let temp = []
               data.document.map(item => {
                  if (item.file && (item.file.fileType === 'dnr' || item.file.fileType === 'insCard')) {
                     temp.push({ ...item.file, fileId: item.file.id })
                  }
               })
               setDocs(data.document)
               setFiles(temp)
            }
            /* if (resp.medical && resp.medical.dnrPolst && resp.medical.dnrPolst.id) {
               setDnr(resp.medical.dnrPolst)
            } */
         }, (message, resp) => {
            if (resp.status === 404) {
               toast.error('No such resident found')
               setTimeout(() => {
                  history.replace('/resident-list')
               }, 500);
            }
            else {
               toast.error(message)
            }
         })
   }

   const getFacilities = () => {
      facilityApi.facilityList({ search: '', page: 1, limit: 99999, companyId: user?.company?.id },
         (message, resp) => {
            if (resp.data.length) {
               let temp = resp.data.map(item => { return { label: item.name, value: item.id, maxResident: item?.maxResident, residentCount: item?.resident?.length } })
               // console.log(temp);
               setFacilities(temp)
               if (temp?.length == 1) {
                  setValue("facility", temp);
               }
            }
         }, (message) => {
            //toast.error(message)
         })
   }

   useEffect(() => {
      if (props.userId && props.activeTab == 1) {
         setUser()
      }
   }, [props.userId, props.activeTab])

   const handleFiles = (e, type) => {
      let file = e.target.files[0]
      if (file) {
         let formData = new FormData()
         formData.append('file', file)
         formData.append('fileType', type)

         commonApi.uploadFile({ data: formData, format: 'resDocFile' },
            (message, resp) => {
               let temp = [...files]
               let obj = {
                  fileId: resp.id,
                  path: resp.path,
                  originalName: resp.originalName,
                  description: '',
                  enteredDate: moment(new Date()).format('YYYY-MM-DD'),
                  isNew: true
               }
               if (type === 'dnr') {
                  obj['fileType'] = 'dnr'
                  obj['type'] = 'DNR/POLST'
               }
               else if (type === 'insCard') {
                  obj['fileType'] = 'insCard'
                  obj['type'] = 'Insurance Card'
               }
               else if (type === 'medical') {
                  obj['fileType'] = 'medical'
                  obj['type'] = 'Medical Condition'
                  document.getElementById(e.target.id).value = ''
               }
               else if (type === 'carePlan') {
                  obj['fileType'] = 'carePlan'
                  obj['type'] = 'Care Plan'
                  document.getElementById(e.target.id).value = ''
               }
               temp.unshift(obj)
               setFiles(temp)
               toast.success('Please save to confirm changes')
            }, (message, resp) => {
               toast.error(message)
            })
      }
   }

   const uploadImg = () => {
      let formData = new FormData()
      formData.append('file', photo.file)
      formData.append('fileType', 'residentPhoto')

      commonApi.uploadFile({ data: formData, format: 'image' },
         (message, resp) => {
            setPhoto(resp)
            setOpen(false)
            toast.success('Please save to confirm changes')
         }, (message) => {
            toast.error(message)
         })
   }

   const onSubmit = (data) => {
      const getValue = getValues()

      data['documents'] = files.length ? files.filter(item => item.isNew === true) : []
      data.isActive = data.isActive == '1' ? true : false
      data.birthDate = data.birthDate ? moment(data.birthDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      data.movedInDate = data.movedInDate ? moment(data.movedInDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      data.movedOut = data.movedOut ? moment(data.movedOut, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      data.depositAccepted = data.depositAccepted ? moment(data.depositAccepted, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      data.deathDate = data.deathDate ? moment(data.deathDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : ''
      data.gender = data.gender.value
      // data.isVaccinated = data.isVaccinated != undefined ? data.isVaccinated.value : 'no';
      data.isVaccinated = getValue?.isVaccinated ? getValue?.isVaccinated : 'no'
      data.vaccineComment = data.vaccineComment ? data.vaccineComment : "No comment"
      data.facilityId = facilities?.length == 1 ? facilities[0].value : parseInt(data.facility.value)
      if (photo && photo.id) {
         data.photoId = photo.id
      }
      //data.dnrPolstId = dnr.id ? dnr.id : null
      if (!isResiding) {
         data.residing = null
         data.hospice = ''
         data.hospiceContName = ''
         data.hospicePhoneNo = ''
      }
      if (!data.email) {
         delete data.email
      }

      if (data.facility?.residentCount >= data.facility?.maxResident) {
         console.log("data.facility?.residentCount", "if");
         return;
      } else {
         if (props.userId) {
            data['id'] = props.userId
            data['removePhoto'] = isPhotoRemoved;
            // if (isWizard === true) {
            //    props.setforceExit(false);
            // }

            data.isVaccinated = isVaccinated === 'yes' ? 'yes' : (data?.isVaccinated === 'unknown' ? 'unknown' : (data?.isVaccinated === 'no' ? 'No' : null));
            if ((data.facilityId == '' || isNaN(data.facilityId)) && ((data.facility == undefined) || data.facility == '')) {
               trigger();

            } else {
               residentApi.updateResident(data,
                  (message, resp) => {
                     toast.success('Resident updated successfully')
                     setTimeout(() => {
                        //history.push('/resident-list')
                        props.changeStep('2')
                     }, 500);
                  }, (message) => {
                     toast.error(message)
                  })
            }
         }
         else {
            console.log("data", data,"else");
            if ((data.firstName == '' || data.firstName == undefined) || (data.lastName == '' || data.lastName == undefined) || (data.gender == '' || data.gender == undefined) || (data.facility == '' || data.facility == undefined) || (data.movedInDate == '' || data.movedInDate == undefined)) {
               trigger();

            } else {
               data.isVaccinated = isVaccinated === 'yes' ? 'yes' : (data?.isVaccinated === 'unknown' ? 'unknown' : (data?.isVaccinated === 'no' ? 'No' : null));
            
            residentApi.addResident(data,
               (message, resp) => {
                  toast.success('Personal details added successfully')


                  if (isWizard === true) {
                     props.setforceExit(false);
                  }

                  props.handleNext(resp.id)
                  props.changeStep('2')
                  //history.push('/resident-list')
               }, (message) => {
                  toast.error(message)
               })
            }
         }
      }
   }

   const clearFile = (id) => {
      if (id === 'photo') {
         setPhoto({})
      }
   }

   const removePhoto = (id) => {
      clearFile(id)
      setPhotoRemoved(true)
   }

   const replacePhoto = () => {
      clearFile("photo")
      document.getElementById("#custom-file-input").click()
   }

   const removeFile = (item) => {
      let temp = files.find(ob => ob.fileId === item.fileId)
      showConfirm(temp.fileId, temp.isNew)
   }

   useEffect(() => {
      if (isResiding === '') {
         setValue('isResiding', '')
      }
   }, [isResiding])

   useEffect(() => {

      if (isVaccinated === 'yes') {
         setValue('isVaccinated', 'yes')
      }
      else if (isVaccinated === 'unknown') {
         setValue('isVaccinated', 'unknown')
      }
      else {
         setValue('isVaccinated', 'no')
      }
   }, [isVaccinated])

   const onchange = (event) => {
      // console.log('event.target.value', event.target.value)
   }

   const downloadFile = (name, id) => {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      setAlert(
         <SweetAlert
            success
            title="Downloading!"
            onConfirm={() => { }}
            onCancel={() => { }}
            customButtons={<React.Fragment />}
         >
            <div class="progress mt-2" style={{ height: "15px" }}>
               <div class="progress-bar" style={{ width: "0%", height: "15px" }} id="progress"></div>
            </div>
         </SweetAlert>
      )
      axios({
         url: `${APIS.COMMON.DONWLOAD_FILE}?id=${id}`,//your url
         method: 'GET',
         responseType: 'blob',
         onDownloadProgress: progressEvent => {
            let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            let ele = document.getElementById('progress')
            if (ele) {
               ele.style.width = progress + '%'
               ele.innerHTML = progress + '%'
            }
         }
      }).then(blob => {
         let blobs = new Blob([blob.data]),
            url = window.URL.createObjectURL(blobs);
         a.href = url;
         a.download = name;
         a.click();
         window.URL.revokeObjectURL(url);
         setAlert(null)
      }).catch(e => {
         toast.error('Could not download file')
         setAlert(null)
      })
   }

   const handleImg = (e) => {
      let file = e.target.files[0]
      if (file) {
         let url = URL.createObjectURL(file)
         setPhoto({ fullUrl: url, file })
         setOpen(true)
         setPhotoRemoved(false)
      }
   }

   const closeModal = () => {
      setOpen(false)
      setPhoto({})
      document.getElementById('photo').value = ''
   }

   const showConfirm = (id, isNew) => {
      setAlert(
         <SweetAlert
            warning
            showCancel
            confirmBtnText="Continue!"
            confirmBtnBsStyle="danger cancel-padding"
            title="Delete Document?"
            onConfirm={() => isNew ? deleteCommonFunc(id) : deleteDnrFunc(id)}
            onCancel={() => setAlert(null)}
            focusCancelBtn={false} >
            Are you sure?
         </SweetAlert>
      )
   }

   const deleteCommonFunc = (id) => {
      setAlert(null)
      commonApi.deleteFiles({ ids: [id] },
         (message, resp) => {
            toast.success('Document deleted successfully')
            let temp = [...files]
            temp.splice(temp.findIndex(item => item.fileId === id), 1)
            setFiles(temp)
         }, (message) => {
            toast.error(message)
         })
   }

   const deleteDnrFunc = (id) => {
      let docId = docs.find(item => item.file.id === id)
      setAlert(null)
      residentApi.deleteResidentDoc({ id: docId.id },
         (message, resp) => {
            toast.success('Document deleted successfully')
            let temp = [...files]
            temp.splice(temp.findIndex(item => item.fileId === id), 1)
            setFiles(temp)
         }, (message) => {
            toast.error(message)
         })
   }

   const onError = (errors, e) => {
      console.log("errorserrorserrorserrorserrors", errors);
      let errorsArray = Object.keys(errors)
      if (errorsArray.length) {
         window.scrollTo({ top: 0, behavior: 'smooth' });
      }
   }

   useImperativeHandle(ref, () => ({

      getAlert() {
         return getValues()
      }

   }));

   const showAlert = (id) => {
      // console.log(id)
      setAlert(
         <SweetAlert
            warning
            showCancel
            confirmBtnText="Continue!"
            confirmBtnBsStyle="danger"
            title="Delete resident?"
            onConfirm={() => confirmDeleteResident(id)}
            onCancel={() => setAlert(null)}
            focusCancelBtn={false}
         >
            Are you sure? If a resident passed away or moved out we suggest marking them inactive, not delete. Inactive keeps their records per state requirements. To mark inactive, go in to the residents page and scroll to the bottom and choose inactive and save.
         </SweetAlert>
      )
   }

   const confirmDeleteResident = (id) => {
      setAlert(null)
      residentApi.deleteResident({ id },
         (message, resp) => {
            toast.success('Resident deleted successfully')
            history.push(`/resident-list`)
         }, (message) => {
            toast.error(message)
         })
   }


   return (
      <>
         {/* {console.log({ errors, values: getValues() ,facilities})} */}
         {alert}
         {isDisplay && <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="p-3 mt-3">
               <h5 className="mb-4">Resident Details</h5>
               <div className="row">
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="firstName"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.firstName}
                                 label='First Name'
                                 placeholder='First name'
                                 required
                                 type='text'
                                 maxLength={20}
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="lastName"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.lastName}
                                 label='Last Name'
                                 placeholder='Last name'
                                 required
                                 type='text'
                                 maxLength={40}
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Moved in Date <span className="text-danger">*</span></label>
                        <Controller
                           defaultValue={null}
                           control={control}
                           name="movedInDate"
                           render={({ ref, value, ...props }) => (
                              <DateInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.movedInDate}
                                 placeholder='mm-dd-yyyy'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>

               <div className="row">
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="nickname"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.nickname}
                                 label='Nickname'
                                 maxLength={40}
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Gender <span className="text-danger"><span className="text-danger">*</span></span></label>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="gender"
                           render={({ ref, value, ...rest }) => (
                              <SelectInput
                                 error={errors.gender}
                                 inputref={ref}
                                 value={value}
                                 options={genderOptions}
                                 {...rest}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Date of birth <span className="text-danger">*</span></label>
                        <Controller
                           defaultValue={null}
                           control={control}
                           name="birthDate"
                           render={({ ref, value, ...props }) => (
                              <DateInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.birthDate}
                                 placeholder='mm-dd-yyyy'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>

               <div className="row">
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="language"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.language}
                                 label='Language'
                                 maxLength={40}
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="religion"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.religion}
                                 label='Religion'
                                 type='text'
                                 maxLength={40}
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="race"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.race}
                                 maxLength={40}
                                 label='Race'
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>

               <div className="row">
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Deposit Accepted</label>
                        <Controller
                           defaultValue={null}
                           control={control}
                           name="depositAccepted"
                           render={({ ref, value, ...props }) => (
                              <DateInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.depositAccepted}
                                 placeholder='mm-dd-yyyy'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Death Date</label>
                        <Controller
                           defaultValue={null}
                           control={control}
                           name="deathDate"
                           render={({ ref, value, ...props }) => (
                              <DateInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.deathDate}
                                 placeholder='mm-dd-yyyy'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Moved out Date</label>
                        <Controller
                           defaultValue={null}
                           control={control}
                           name="movedOut"
                           render={({ ref, value, ...props }) => (
                              <DateInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.movedOut}
                                 placeholder='mm-dd-yyyy'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>

               </div>

               <div className="row">
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Facility <span className="text-danger">*</span></label>
                        <Controller
                           defaultValue={facilities}
                           control={control}
                           name="facility"
                           render={({ ref, value, onChange, ...rest }) => (
                              <>
                                 <SelectInput
                                    inputref={ref}
                                    value={value}
                                    options={facilities}
                                    error={facilities?.length == 1 ? '' : errors.facility}
                                    {...rest}
                                    onChange={(e) => {
                                       const selectedFacility = e.value;
                                       console.log('selectedFacility', selectedFacility);
                                       if (selectedFacility) {
                                          const facility = facilities.find((faci) => faci.value === selectedFacility);
                                          console.log('facility', facility);
                                          if (facility?.residentCount >= facility?.maxResident) {
                                             setError('facility', {
                                                message: 'Facility has reached the maximum resident limit',
                                             });
                                          } else {
                                             clearErrors('facility');
                                          }
                                       }

                                       onChange(e);
                                    }}
                                    onBlur={(e) => {
                                       if (!errors.facility) {
                                          clearErrors('facility');
                                       }

                                       onBlur?.(e);
                                    }}
                                 />
                                 {/* {errors.facility && (
                                 <span style={{ color: 'red', fontSize: '12px' }}>
                                   {errors.facility.message}
                                 </span>
                               )} */}
                              </>
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group">
                        <Controller
                           defaultValue=''
                           control={control}
                           name="ssn"
                           render={({ ref, value, ...props }) => (
                              <MaskInput
                                 mask="999-99-9999"
                                 maskChar="SSN"
                                 inputref={ref}
                                 value={value}
                                 error={errors.ssn}
                                 type={'number'}
                                 label='SSN'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Is Covid Vaccinated<span className="text-danger">*</span></label>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="isVaccinated"
                           render={({ ref, value, ...rest }) => (
                              <RadioGroup
                                 value={value}
                                 inputref={ref}
                                 required
                                 options={[
                                    { label: 'Yes', value: 'yes' },
                                    { label: 'No', value: 'no' },
                                    { label: 'Unknown', value: 'unknown' },
                                 ]}
                                 onChange={onchange}
                                 {...rest}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>

               <div className="row">

                  <div className="col-lg-4">
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="email"
                           render={({ ref, value, ...rest }) => (
                              <TextInput
                                 inputref={ref}
                                 type='text'
                                 value={value}
                                 error={errors.email}
                                 label='Email'
                                 placeholder='Email'
                                 {...rest}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4">
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="phone"
                           render={({ ref, value, ...props }) => (
                              <MaskInput
                                 mask="(999) 999-9999"
                                 maskPlaceholder="Phone"
                                 inputref={ref}
                                 value={value}
                                 error={errors.phone}
                                 type={'number'}
                                 label='Phone'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>

                  {isVaccinated === 'yes' && (
                     <div className="col-lg-4" data-select2-id={1}>
                        <div className="form-group" data-select2-id={15}>
                           <label className="form-label">Covid Vaccination Information </label>
                           <Controller
                              defaultValue={null}
                              control={control}
                              name="vaccineComment"
                              render={({ ref, value, ...props }) => (
                                 <TextInput
                                    inputref={ref}
                                    type='textarea'
                                    value={value}
                                    error={errors.description}
                                    label=''
                                    maxLength={250}
                                    {...props}
                                 />
                              )}
                           />
                        </div>
                     </div>
                  )}
               </div>

               <div className="row">

                  <div className="col-lg-4">
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue='0'
                           control={control}
                           name="monthlyRate"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.monthlyRate}
                                 label='Monthly Rate ($)'
                                 maxLength={40}
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4">
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="roomNo"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.roomNo}
                                 label='Building/Floor/Room Number'
                                 maxLength={40}
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>

               </div>
               <div className="row">
                  <div className="col-lg-4">
                     <div className="form-group photo-display">
                        <label className="form-label">Photo</label>
                        {photo && photo.id ?
                           <div>
                              <LazyImage src={photo ? photo.fullUrl : ''} className="d-block mb-3" />
                              {/* <button title="Replace Photo" className="btn btn-info mr-2" type="button" onClick={() => clearFile('photo')}>
                                 <i className="fa fa-redo mr-2"></i> Replace Photo
                              </button> */}
                              <button title="Remove Photo" className="btn btn-danger" type="button" onClick={() => removePhoto('photo')}>
                                 <i className="fa fa-trash mr-2"></i> Remove Photo
                              </button>
                           </div>
                           :
                           <div className="custom-file">
                              <input type="file" className="custom-file-input" onChange={(e) => handleImg(e, 'residentPhoto')} accept=".jpg,.jpeg,.png" id="photo" />
                              <label className="custom-file-label" htmlFor="photo">Choose file</label>
                           </div>
                        }
                     </div>
                  </div>
                  <div className="col-lg-4">
                     <div className="form-group">
                        <label className="form-label">DNR/POLST</label>
                        {files.find(item => item.fileType === 'dnr') ?
                           <div>
                              <button className="btn btn-primary waves-light my-2" type="button" onClick={() => downloadFile(files.find(item => item.fileType === 'dnr').originalName, files.find(item => item.fileType === 'dnr').fileId)}>{files.find(item => item.fileType === 'dnr').originalName}</button><br />
                              <button type="button" onClick={() => removeFile(files.find(item => item.fileType === 'dnr'))} className="btn btn-danger mt-2">Remove File</button>
                           </div>
                           :
                           <div className="custom-file">
                              <input type="file" className="custom-file-input" onChange={(e) => handleFiles(e, 'dnr')} accept=".png, .jpg, .jpeg, .pdf, .gif, .tiff, .doc, .docx, .xls, .xlsx" id="dnr" />
                              <label className="custom-file-label" htmlFor="dnr">Choose file</label>
                           </div>
                        }
                     </div>


                  </div>
               </div>

               <hr style={{ height: '4px', backgroundColor: '#e8e8e8' }} />
               <h5 className="mt-4 mb-3" id="home-health">Care Type</h5>

               <div className="row">
                  <div className="col-lg-6">
                     <div className="form-group">
                        <Controller
                           defaultValue=''
                           control={control}
                           name="residing"
                           render={({ ref, value, ...rest }) => (
                              <RadioGroup
                                 value={value}
                                 inputref={ref}
                                 options={[
                                    { label: 'Home Health', value: 'homehealth' },
                                    { label: 'Hospice', value: 'hospice' },
                                    { label: 'None', value: '' }
                                 ]}
                                 {...rest}
                              />
                           )}
                        />

                     </div>
                  </div>
                  {/* <div className="col-lg-6">
                     {isResiding && <span onClick={() => setValue('residing', '')} style={{ textDecoration: 'underline' }} className="ml-3 text-primary cursor-pointer">Reset care type</span>}
                  </div> */}
               </div>

               {isResiding && <>
                  <div className="row mt-4">
                     <div className="col-lg-4">
                        <div className="form-group">
                           <Controller
                              defaultValue=''
                              control={control}
                              name="hospice"
                              render={({ ref, value, ...props }) => (
                                 <TextInput
                                    inputref={ref}
                                    value={value}
                                    error={errors.hospice}
                                    label='Name'
                                    type='text'
                                    {...props}
                                 />
                              )}
                           />
                        </div>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-lg-6">
                        <div className="form-group">
                           <Controller
                              defaultValue=''
                              control={control}
                              name="hospiceContName"
                              render={({ ref, value, ...props }) => (
                                 <TextInput
                                    inputref={ref}
                                    value={value}
                                    error={errors.hospiceContName}
                                    label='Contact Name'
                                    type='text'
                                    {...props}
                                 />
                              )}
                           />
                        </div>
                     </div>
                     <div className="col-lg-6">
                        <div className="form-group">
                           <Controller
                              defaultValue=''
                              control={control}
                              name="hospicePhoneNo"
                              render={({ ref, value, ...props }) => (
                                 <MaskInput
                                    mask="(999) 999-9999"
                                    inputref={ref}
                                    value={value}
                                    error={errors.hospicePhoneNo}
                                    label='Contact Phone'
                                    type={'number'}
                                    {...props}
                                 />
                              )}
                           />
                        </div>
                     </div>
                  </div>
               </>}

               <hr style={{ height: '4px', backgroundColor: '#e8e8e8' }} />
               <h5 className="mt-4 mb-3" id="medical">Medical Details</h5>

               <div className="row">
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="insuranceProvider"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.insuranceProvider}
                                 label='Insurance Provider'
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="accountNo"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.accountNo}
                                 label='Account Number'
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="groupNo"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.groupNo}
                                 label='Group Number'
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>

               <div className="row">
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="medicare"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.medicare}
                                 label='Medicare'
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="medical"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.medical}
                                 label='Medical'
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
                  <div className="col-lg-4" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="physician"
                           render={({ ref, value, ...props }) => (
                              <TextInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.physician}
                                 label='Attending Physician'
                                 type='text'
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>

               <div className="row">
                  <div className="col-lg-4">
                     <div className="form-group">
                        <label className="form-label">Insurance Card</label>
                        {files.find(item => item.fileType === 'insCard') ?
                           <div>
                              <button className="btn btn-primary waves-light my-2" type="button" onClick={() => downloadFile(files.find(item => item.fileType === 'insCard').originalName, files.find(item => item.fileType === 'insCard').fileId)}>{files.find(item => item.fileType === 'insCard').originalName}</button><br />
                              <button type="button" onClick={() => removeFile(files.find(item => item.fileType === 'insCard'))} className="btn btn-danger mt-2">Remove File</button>
                           </div>
                           :
                           <div className="custom-file">
                              <input type="file" className="custom-file-input" onChange={(e) => handleFiles(e, 'insCard')} accept=".png, .jpg, .jpeg, .pdf, .gif, .tiff, .doc, .docx, .xls, .xlsx" id="insCard" />
                              <label className="custom-file-label" htmlFor="insCard">Choose file</label>
                           </div>
                        }
                     </div>
                  </div>
               </div>

               <div className="row mt-2">
                  <div className="col-lg-12">
                     <div className="form-group">
                        <label className="form-label">Medical Conditions</label>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="medicalCond"
                           render={({ ref, value, ...props }) => (
                              <EditorInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.medicalCond}
                                 {...props}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>
               <div>
                  <button className="btn btn-outline-dark" type="button" onClick={() => medicalFileRef.current.click()}>Upload Medical File</button>

                  <input type="file" className="d-none" onChange={(e) => handleFiles(e, 'medical')} accept=".png, .jpg, .jpeg, .pdf, .gif, .tiff, .doc, .docx, .xls, .xlsx" id="medical" ref={medicalFileRef} />

                  {files.filter(item => item.fileType === 'medical').length ?
                     <div className="mt-4">
                        <p className="mb-2"><strong>Files to be uploaded</strong></p>
                        <div className="table-responsive">
                           <table className="table table-striped table-bordered">
                              <thead>
                                 <tr>
                                    <th>File Name</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {files.filter(item => item.fileType === 'medical').map((item, i) =>
                                    <tr key={'medical' + i}>
                                       <td>{item.originalName}</td>
                                       <td>
                                          <i onClick={() => removeFile(item)} className="fa fa-trash cursor-pointer" data-toggle="tooltip" title="Delete Document" />
                                       </td>
                                    </tr>)}
                              </tbody>
                           </table>
                        </div>
                     </div>
                     : null}
               </div>

               <div className="row mt-4">
                  <div className="col-lg-12">
                     <div className="form-group">
                        <label className="form-label">Care Plan</label>
                        <Controller
                           defaultValue=''
                           control={control}
                           name="carePlan"
                           render={({ ref, value, ...rest }) => (
                              <EditorInput
                                 inputref={ref}
                                 value={value}
                                 error={errors.carePlan}
                                 {...rest}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>
               <div>
                  <button className="btn btn-outline-dark" type="button" onClick={() => carePlanRef.current.click()}>Upload Care Plan File</button>

                  <input type="file" className="d-none" onChange={(e) => handleFiles(e, 'carePlan')} accept=".png, .jpg, .jpeg, .pdf, .gif, .tiff, .doc, .docx, .xls, .xlsx" id="carePlan" ref={carePlanRef} />

                  {files.filter(item => item.fileType === 'carePlan').length ?
                     <div className="mt-4">
                        <p className="mb-2"><strong>Files to be uploaded</strong></p>
                        <div className="table-responsive">
                           <table className="table table-striped table-bordered">
                              <thead>
                                 <tr>
                                    <th>File Name</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {files.filter(item => item.fileType === 'carePlan').map((item, i) =>
                                    <tr key={'carePlan' + i}>
                                       <td>{item.originalName}</td>
                                       <td>
                                          <i onClick={() => removeFile(item)} className="fa fa-trash cursor-pointer" data-toggle="tooltip" title="Delete Document" />
                                       </td>
                                    </tr>)}
                              </tbody>
                           </table>
                        </div>
                     </div>
                     : null}
               </div>

               <hr style={{ height: '4px', backgroundColor: '#e8e8e8' }} />
               <h5 className="mt-4 mb-3" id="active">Active/Inactive</h5>

               <div className="row">
                  <div className="col-lg-12">
                     <div className="form-group">
                        <Controller
                           defaultValue={'1'}
                           control={control}
                           name="isActive"
                           render={({ ref, value, ...rest }) => (
                              <RadioGroup
                                 name="isActive"
                                 value={value}
                                 inputref={ref}
                                 options={[
                                    { label: 'Active', value: '1' },
                                    { label: 'Inactive', value: '0' }
                                 ]}
                                 {...rest}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>

               <div className="float-right mt-3">
                  {isWizard === true && (
                     <>
                        <button type="button" className="btn btn-outline-danger  mr-2" onClick={() => {
                           skiptonextstep()
                           props.setforceExit(true)
                        }}>End Wizard</button>
                        <button type="button" onClick={() => { getAnotherResident() }} className="btn btn-primary waves-light mr-2">Add Another Resident</button>
                     </>
                  )}

                  {id != '' && (
                     <button type="submit" className="btn btn-warning mr-2" disabled={errors.facility}>Save And Next Tab</button>
                  )}
                  {id === '' && (
                     <button type="submit" className="btn btn-warning mr-2" disabled={errors.facility}>Save And Next Tab</button>
                  )}
                  <button type="button" onClick={() => { saveAndClose() }} disabled={errors.facility} className="btn btn-primary mr-2 mt-0">Save And Close</button>

               </div>
            </div>
         </form>}
         <div className="float-left ml-3">
            {id != '' && (
               <RoleCheckHOC module={MODULES.RESIDENT} operation={[OPERATIONS.DELETE]}>
                  <button type="submit" className="btn btn-danger mt-xs-3" onClick={(e) => { e.stopPropagation(); showAlert(id) }}>Delete Resident</button>
               </RoleCheckHOC>
            )}
         </div>
         <ImageModal
            isOpen={isOpen}
            url={photo && photo.fullUrl ? photo.fullUrl : ''}
            close={closeModal}
            onSave={uploadImg}
         />
      </>
   )
}
)

export default ResidentForm