import React, { Component } from 'react'
import { Link, withRouter, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux'
import { ACTIONS, MODULES, OPERATIONS, ROLE_VALUE, SAGA_ACTIONS, USER_TYPES } from '../config/config';
import RoleCheckHOC from './roleCheckHoc';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReportResModal from '../components/reports/reportResModal';
import CustomerWizardModal from '../container/customer-wizard/customerWizardModal';
//
class AuthLayout extends Component {
   
   state = {
      isShow: true,
      alert: null,
      isModalOpen: false,
      isWizardShow:true,
      wizardAlert:null,
      isWizardModalOpen:false
   }
  
   
   componentDidMount() {
      this.$el = window.$(this.el);
      this.$el.metisMenu();
      new window.SimpleBar(document.getElementById('checking'));
      
   }

   toggle = (e) => {
      e.preventDefault();
      if (this.state.isShow) {
         document.body.classList.add('sidebar-enable', 'vertical-collpsed')
         //document.getElementById("overlay-black").style.display = "block";
      }
      else {
         document.body.classList.remove('sidebar-enable', 'vertical-collpsed')
         //document.getElementById("overlay-black").style.display = "none";
      }
      this.setState({ isShow: !this.state.isShow })
   }

   showConfirm = (e) => {
      e.preventDefault();
      this.setState({
         alert: <SweetAlert
            warning
            showCancel
            confirmBtnText="Continue!"
            confirmBtnBsStyle="danger continue-danger"
            cancelBtnBsStyle="default cancel-danger"
            title="Logout"
            onConfirm={() => this.logout()}
            onCancel={() => this.setState({ alert: null })}
            focusCancelBtn
         >
            Are you sure?
         </SweetAlert>
      })
   }

   showCustomWizardConfirm = (e) => {
      e.preventDefault();
      this.setState({
         alert: <SweetAlert
            warning
            showCancel
            confirmBtnText="Continue!"
            confirmBtnBsStyle="danger"
            title="Customer Wizard"
            onConfirm={() => this.gotoCustomerWizard()}
            onCancel={() => this.setState({ alert: null })}
            focusCancelBtn
         >
            Do you want to go customer wizard section?
         </SweetAlert>
      })
   }

   gotoCustomerWizard = () =>{
      this.props.history.push('/customer-wizard');
   }

   logout = () => {
      this.setState({ alert: null })
      this.props.dispatch({ type: ACTIONS.LOADER.SHOW_LOADER })
      this.props.dispatch({
         type: SAGA_ACTIONS.USER.LOGOUT,
         callbackSuccess: (resp) => {
            this.props.dispatch({ type: ACTIONS.LOADER.HIDE_LOADER })
            this.props.history.push('/login');
            localStorage.removeItem(ROLE_VALUE)
            toast.success('Logout Successful');
         },
         callbackError: e => {
            this.props.dispatch({ type: ACTIONS.LOADER.HIDE_LOADER })
            toast.error('Error logging out')
         }
      });
   }

   goToLink = (e, link) => {
      e.preventDefault();
      if (window.matchMedia("(max-width: 767px)").matches) {
         this.toggle(e)
      }
      if (link.includes('pass-meds')) {
         this.props.dispatch({ type: ACTIONS.MEDPASS.SET_TAB_INDEX, payload: '1' })
         this.props.dispatch({ type: ACTIONS.MEDPASS.SET_PAGE, payload: 1 })
      }
      // if (link.includes('create-resident') && link.includes('family-members')) { {
      //    this.props.history.push(link)
      // }
      
      this.props.history.push(link)
      window.location.reload()
   }
   

   render() {

      const router = window.location.pathname;

      console.log('pathnamepathnamepathnamepathnamepathname',router.substring(0,16));
      const { user } = this.props
      const role_value = localStorage.getItem(ROLE_VALUE);
      console.log('role_value', role_value)
      return (
         <div id="layout-wrapper" >
            {this.state.alert}
            <header id="page-topbar">
               <div className="navbar-header">
               <div className="profile-img-block">
                  <img className="rounded-circle header-profile-user cursor-pointer" src={user.photo && user.photo.fullUrl ? user.photo.fullUrl : "/assets/images/avatar-2.jpg"} alt="Header Avatar" onClick={() => this.props.history.push('/profile')}/>
               </div>
                  <div className="d-flex">
                     {/* LOGO */}
                     <div className="navbar-brand-box">
                        <Link to="/dashboard" className="logo">
                           <span className="logo-lg">
                              <img src="/assets/images/logo-light.png" alt="" height="100%" />
                           </span>
                        </Link>
                     </div>
                     <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn" onClick={this.toggle}>
                        <i className="fa fa-fw fa-bars" />
                     </button>
                     {/* App Search*/}
                  </div>
                  <div className="d-flex">
                     <a className="text-danger no-hover-background d-flex align-items-center" href="http://seniorws.com/support" target="_blank" ><i className="fas fa-question question-icon mr-3" /></a>
                  
                     {router.substring(0,16) != '/customer-wizard' && (
                        <button className="btn btn-outline-danger mr-2" onClick={() => this.setState({ isWizardModalOpen: true })}>Customer Wizard</button>
                     )}  
                     {/* <RoleCheckHOC module={MODULES.REPORT} operation={[OPERATIONS.READ]}>                                       */}
                     <button className="btn btn-outline-danger" onClick={() => this.setState({ isModalOpen: true })}>911 Report</button>
                     {/* </RoleCheckHOC>  */}
                     <div className="dropdown d-flex">
                        <a className="dropdown-item text-danger no-hover-background d-flex align-items-center" href="#" onClick={this.showConfirm}><i className="bx bx-power-off font-size-22 align-middle mr-1 text-danger" /></a>
                        {/* <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <img className="rounded-circle header-profile-user" src="/assets/images/avatar-2.jpg" alt="Header Avatar" />
                           <span className="d-none d-xl-inline-block ml-1" key="t-henry">Henry</span>
                           <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                        </button> */}
                        {/* <div className="dropdown-menu dropdown-menu-right"> */}
                        {/* item*/}
                        {/* <a className="dropdown-item" href="#"><i className="bx bx-user font-size-16 align-middle mr-1" /> <span key="t-profile">My Profile</span></a>
                           <div className="dropdown-divider" />
                           <a className="dropdown-item text-danger" href="#" onClick={this.logout}><i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" /> <span key="t-logout">Logout</span></a>
                        </div> */}
                     </div>
                  </div>
               </div>
            </header>
            {/* ========== Left Sidebar Start ========== */}
            <div className="vertical-menu" >
               <div data-simplebar className="h-100" id="checking">
                  {/*- Sidemenu */}
                  <div id="sidebar-menu">
                     {/* Left Menu Start */}
                     <ul className="metismenu list-unstyled" id="side-menu" ref={el => this.el = el}>
                        <div className="dropdown d-inline-block leftpanel_user">
                           <button type="button" className="btn header-item waves-effect text-left w-100" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <img className="rounded-circle header-profile-user cursor-pointer" src={user.photo && user.photo.fullUrl ? user.photo.fullUrl : "/assets/images/avatar-2.jpg"} alt="Header Avatar" onClick={() => this.props.history.push('/profile')}/>
                              <div className="text-center w-100 pt-2 pb-2">
                                 <span className="d-xl-inline-block ml-1" key="t-henry">{user && user.firstName || ''}</span>
                                 <i className="mdi mdi-chevron-down d-xl-inline-block" />
                              </div>
                           </button>
                           <div className="dropdown-menu dropdown-menu-right">
                              {/* item*/}
                              <Link className="dropdown-item" to="/profile"><span key="t-profile">My Profile</span></Link>
                           </div>
                        </div>
                        {/* <li className="menu-title" key="t-menu">Menu</li> */}
                        <li>
                           <a href="#" onClick={(e) => this.goToLink(e, '/dashboard')} className="waves-effect">
                              <i className="bx bx-home-circle" />
                              <span key="t-layouts">Home</span>
                           </a>
                        </li>
                        {/* <li>
                           <a href="#" className="has-arrow waves-effect">
                              <i className="bx bx-layout" />
                              <span key="t-layouts">Facilities</span>
                           </a>
                           <ul className="sub-menu mm-collapse " aria-expanded="false" style={{}}>
                              <li><a href="#" key="t-wallet">01</a></li>
                           </ul>
                        </li> */}
                        {/*  <li>
                           <a href="#" className="has-arrow waves-effect">
                              <i className="bx bx-shape-circle" />
                              <span key="t-layouts">Manage Residents</span>
                           </a>
                           <ul className="sub-menu mm-collapse " aria-expanded="false" style={{}}>
                              <li><Link to="/manage-residence" key="t-wallet">Manage Residence</Link></li>
                              <li><a href="#" key="t-buy">02</a></li>
                           </ul>
                        </li> */}
                        {/* <li>
                           <a href="#" className="has-arrow waves-effect">
                              <i className="bx bx-doughnut-chart" />
                              <span key="t-layouts">Add Incident</span>
                           </a>
                           <ul className="sub-menu mm-collapse " aria-expanded="false" style={{}}>
                              <li><a href="#" key="t-wallet">01</a></li>
                           </ul>
                        </li> */}
                        <RoleCheckHOC module={MODULES.STAFF} operation={[OPERATIONS.LIST]}>
                           <li>
                              <a href="#" onClick={(e) => this.goToLink(e, '/staff-list')} className="waves-effect">
                                 <i className="bx bx-smile" />
                                 <span key="t-layouts">Staff</span>
                              </a>
                           </li>
                        </RoleCheckHOC>
                        <RoleCheckHOC module={MODULES.ROLES} operation={[OPERATIONS.LIST]}>
                           <li>
                              <a href="#" onClick={(e) => this.goToLink(e, '/role-list')} className="waves-effect">
                                 <i className="bx bx-shape-square" />
                                 <span key="t-layouts">Roles</span>
                              </a>
                           </li>
                        </RoleCheckHOC>
                        <RoleCheckHOC module={MODULES.RESIDENT} operation={[OPERATIONS.LIST]}>
                           <li>
                              <a href="#" onClick={(e) => this.goToLink(e, '/resident-list')} className="waves-effect">
                                 <i className="bx bxs-user-plus" />
                                 <span key="t-layouts">Residents</span>
                              </a>
                           </li>
                        </RoleCheckHOC>
                        <RoleCheckHOC module={MODULES.FACILITY} operation={[OPERATIONS.LIST]}>
                           <li>
                              <a href="#" onClick={(e) => this.goToLink(e, '/facility-list')} className="waves-effect">
                                 <i className="bx bxs-home" />
                                 <span key="t-layouts">Facilities</span>
                              </a>
                           </li>
                        </RoleCheckHOC>
                        <RoleCheckHOC module={MODULES.PASS_MEDS} operation={[OPERATIONS.LIST]}>
                           <li>
                              <a href="#" onClick={(e) => this.goToLink(e, '/pass-meds')} className="waves-effect">
                                 <i className="bx bx-calendar-plus" />
                                 <span key="t-layouts">Pass Meds</span>
                              </a>
                           </li>
                        </RoleCheckHOC>
                        <RoleCheckHOC module={MODULES.REPORT} operation={[OPERATIONS.READ]}>
                        <li>
                           <a href="#" onClick={(e) => this.goToLink(e, '/reports')} className="waves-effect">
                              <i className="bx bxs-report" />
                              <span key="t-layouts">Reports</span>
                           </a>
                        </li>
                        </RoleCheckHOC>
                        <RoleCheckHOC module={MODULES.CONTACT} operation={[OPERATIONS.LIST]}>
                        <li>
                           <a href="#" onClick={(e) => this.goToLink(e, '/contacts-list')} className="waves-effect">
                              <i className="bx bxs-contact" />
                              <span key="t-layouts">Reusable Contacts</span>
                           </a>
                        </li>
                        </RoleCheckHOC>
                        {user.roles != "admin" && <li>
                           <a href="#" onClick={(e) => this.goToLink(e, '/approval-list')} className="waves-effect">
                              <i className="fas fa-calendar-check" />
                              <span key="t-layouts">Approvals</span>
                           </a>
                        </li>}

                        {/* {user.roles != "admin" &&  */}
                        {/* <li>
                           <a href="#" onClick={(e) => this.goToLink(e, '/inventory')} className="waves-effect">
                              <i className="fa fa-copy" />
                              <span key="t-layouts">Medication History</span>
                           </a>
                        </li> */}
                        {/* // } */}

                        {/* {role_value && role_value == "default" && <li>
                           <a href="#" onClick={(e) => this.goToLink(e, '/incident-approval')} className="waves-effect">
                              <i className="fa fa-wheelchair" />
                              <span key="t-layouts">Incident Approvals</span>
                           </a>
                        </li>} */}
                        <RoleCheckHOC module={MODULES.ANNOUNCEMENTS} operation={[OPERATIONS.LIST]}>
                           <li>
                              <a href="#" onClick={(e) => this.goToLink(e, '/announcement-list')} className="waves-effect">
                                 <i className="bx bx-message" />
                                 <span key="t-layouts">Announcements</span>
                              </a>
                           </li>
                        </RoleCheckHOC>
                        
                           {/* <li>
                              <a href="#" onClick={(e) => this.goToLink(e, '/customer-wizard')} className="waves-effect">
                                 <i className="bx bx-message" />
                                 <span key="t-layouts">Customer Wizard</span>
                              </a>
                           </li> */}
                        <RoleCheckHOC module={MODULES.RESIDENT} operation={[OPERATIONS.CREATE]}>
                           <li>
                              <a href="#" onClick={(e) => this.goToLink(e, `/create-resident/family-members`)} className="waves-effect">
                                 <i className="bx bxs-user-plus" />
                                 <span key="t-layouts">Add Resident</span>
                              </a>
                           </li>
                        </RoleCheckHOC>
                     </ul>
                  </div>
                  {/* Sidebar */}
               </div>
            </div>
            {/* Main content here */}
            <div id="overlay-black" onClick={this.toggle} />
            <div className="main-content">

               {this.props.children}

               <div className="my-2" />
               <footer className="footer text-center">
                  <div className="container-fluid">
                     <div className="row">
                        <div className="col-sm-12">
                        &#169; {new Date().getFullYear()} Senior Wellness Solutions
 							</div>
                     </div>
                  </div>
               </footer>
            </div>
            <ReportResModal isOpen={this.state.isModalOpen} close={() => this.setState({ isModalOpen: false })} type="911" />
            <CustomerWizardModal isOpen={this.state.isWizardModalOpen} close={() => this.setState({ isWizardModalOpen: false })}/>
         </div>
      )
   }
}

const mapStateToProps = (state) => {
   const user = state && state.user && state.user.user ? state.user.user : null

   return {
      user
   }
}

export default connect(mapStateToProps)(withRouter(AuthLayout))